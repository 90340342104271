import { ChatSidebar } from '@gastfreund/roq-ui-react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import useParamConversationId from '../../hooks/router/params/useParamConversationId';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import { getChatConversationUrl } from '../../routes/urls/chat';

import { useIsChatEnabled } from './useIsChatEnabled';

/**
 * Chat sidebar, containing the chat list.
 *
 * @returns Chat sidebar
 */
export const ChatList: FC = () => {
  const conversationId = useParamConversationId();
  const hotelId = useParamHotelId();
  const isEnabled = useIsChatEnabled();
  const navigate = useNavigate();

  if (isEnabled !== true || hotelId === null) {
    return null;
  }

  return (
    <ChatSidebar
      generateConversationLink={({ id }) => {
        return getChatConversationUrl(hotelId, id);
      }}
      onCurrentConversationIdChanged={id => {
        if (id === null || id === conversationId) {
          return;
        }

        navigate(getChatConversationUrl(hotelId, id));
      }}
    />
  );
};
