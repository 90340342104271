/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Color: { input: string; output: string };
  Date: { input: string; output: string };
};

/** Subscription action type */
export enum ActionType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED',
  Archived = 'ARCHIVED',
  Unarchived = 'UNARCHIVED',
}

/** Available localizations */
export enum Locale {
  En = 'en',
  De = 'de',
  Fr = 'fr',
  Nl = 'nl',
  It = 'it',
}

/** Translation target language */
export enum TargetLanguage {
  Ar = 'ar',
  Bg = 'bg',
  Br = 'br',
  Cs = 'cs',
  Da = 'da',
  De = 'de',
  El = 'el',
  En = 'en',
  Es = 'es',
  Et = 'et',
  Fi = 'fi',
  Fr = 'fr',
  Hu = 'hu',
  Id = 'id',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Lt = 'lt',
  Lv = 'lv',
  Nb = 'nb',
  Nl = 'nl',
  Pl = 'pl',
  Pt = 'pt',
  Ro = 'ro',
  Ru = 'ru',
  Sk = 'sk',
  Sl = 'sl',
  Sv = 'sv',
  Tr = 'tr',
  Uk = 'uk',
  Us = 'us',
  Zh = 'zh',
}

/** Action result status */
export type ActionResult = {
  __typename?: 'ActionResult';
  /** Indicates if the action was successful */
  status: Scalars['Boolean']['output'];
};

/** Time unit for reminder and due date offset */
export enum TimeUnit {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
}

export type Node = Message | Comment;

export type PaginationInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The flag that indicates if there is more data to fetch */
  hasNextPage: Scalars['Boolean']['output'];
  /** The flag that indicates if there are data before this page */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The cursor of the start node */
  startCursor: Scalars['ID']['output'];
  /** The cursor of the end node */
  endCursor: Scalars['ID']['output'];
};

export type NodesInfo = {
  __typename?: 'NodesInfo';
  /** Number of returned nodes */
  returned: Scalars['Int']['output'];
  /** Total number of nodes */
  total: Scalars['Int']['output'];
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  /** Result nodes */
  nodes: Array<Node>;
  /** Additional info of page result */
  pageInfo: PageInfo;
};

export type PaginationResultExtended = {
  __typename?: 'PaginationResultExtended';
  /** Result nodes */
  nodes: Array<Node>;
  /** Additional info of page result */
  pageInfo: PageInfo;
  /** Additional info of nodes */
  nodesInfo: NodesInfo;
};

/** I18n default localization type */
export type I18nLocalization = {
  __typename?: 'I18nLocalization';
  /** Value to be localized */
  value: Scalars['String']['output'];
  /** Localization */
  label: Scalars['String']['output'];
};

/** I18n target language localization type */
export type I18nTargetLanguage = {
  __typename?: 'I18nTargetLanguage';
  /** Value to be localized */
  value?: Maybe<Scalars['String']['output']>;
  /** Localization */
  label: Scalars['String']['output'];
};

/** I18n role localization type */
export type I18nRole = {
  __typename?: 'I18nRole';
  /** Value to be localized */
  value: Scalars['String']['output'];
  /** Localized role title */
  title: Scalars['String']['output'];
  /** Localized role description */
  description: Scalars['String']['output'];
};

/** I18n (localization) */
export type I18n = {
  __typename?: 'I18n';
  /** Localized target languages */
  targetLanguages: Array<I18nTargetLanguage>;
  /** Localized roles */
  roles: Array<I18nRole>;
  /** Localized notifications */
  notifications: Array<I18nLocalization>;
  /** Other translations */
  translations: Array<I18nLocalization>;
};

export type Query = {
  __typename?: 'Query';
  /** I18n localization */
  localization: I18n;
  /** Get user object by an access token */
  me: User;
  /** Get active users by the given hotel mongo ID in a header */
  users: Array<User>;
  /** Get all users (including deactivated users) by the given hotel mongo ID in a header */
  usersAll: Array<User>;
  /** Get user by the given user mongo ID */
  user: User;
  /** Get user notifications */
  userNotifications: UserNotifications;
  /** Get all user group by the given hotel ID in the header */
  userGroups: Array<UserGroup>;
  /** Get user group by ID */
  userGroup: UserGroup;
  /** Get hotel by the given mongo ID */
  hotel?: Maybe<Hotel>;
  /** Get active hotels for the authenticated user */
  hotels: Array<Hotel>;
  /** Get all hotels for analytics (Streamlit) */
  hotelsForAnalytics: Array<HotelForAnalytics>;
  /** Get label by ID */
  label?: Maybe<Label>;
  /** Get all labels for a given hotel (hotel id must in the request header) */
  labels: Array<Label>;
  /** Get all topics visible on the feed page (the limited scope) */
  topics: Array<Topic>;
  /** Get all topics visible on the settings page (the full scope) */
  topicsAll: Array<Topic>;
  /** Get topic by the given ID */
  topic: Topic;
  /** Get assignable topic members to a message for the given topic ID */
  topicAssignableMembers: Array<User>;
  /** Get assignable topic groups to a message for the given topic ID */
  topicAssignableGroups: Array<UserGroup>;
  /** Get message by ID */
  message?: Maybe<Message>;
  /** Bi-directional pagination */
  messages: PaginationResult;
  /** Get all push subscription for a given user and hotel */
  pushSubscriptions: Array<PushNotification>;
  /** Get comment by mongo ID */
  comment?: Maybe<Comment>;
  /** Bi-directional pagination */
  comments: PaginationResult;
  /** Get a S3 upload pre-signed post fields for the given file names */
  fileSignedUploadPostFields: Array<Scalars['String']['output']>;
  /** Get a S3 download pre-signed URL for message */
  fileSignedDownloadUrlForMessage: Scalars['String']['output'];
  /** Get a S3 download pre-signed URL for comment */
  fileSignedDownloadUrlForComment: Scalars['String']['output'];
  /** Search messages */
  messageSearch: SearchResult;
  /** Search messages ranked by relevance */
  messageRankedSearch: SearchResult;
  /** Get searchQuery by ID */
  searchQuery: SearchQuery;
  /** Get all search queries of a certain type. Default is SMART_VIEW. */
  searchQueries: Array<SearchQuery>;
  /** Get dashboard KPIs */
  dashboardKpi: Array<DashboardKpi>;
  /** Re-sync (all or hotels) messages with Elasticsearch */
  messageSync: ActionResult;
  /** Get all templates that authenticated user can edit */
  templatesEditable: Array<Template>;
  /** Get all templates in the topic that user can select */
  templatesInTopic: Array<Template>;
  /** Get the single template by the given ID */
  template: Template;
  /** Get reservation status for the hotel */
  reservationStatus: ReservationStatus;
  /** Get reservations for the hotel */
  reservations: Array<Reservation>;
  /** Tasks */
  tasks: PaginationResultExtended;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserNotificationsArgs = {
  type: NotificationType;
};

export type QueryUserGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryHotelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLabelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTopicArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTopicAssignableMembersArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTopicAssignableGroupsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMessagesArgs = {
  topicId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCommentsArgs = {
  messageId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFileSignedUploadPostFieldsArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QueryFileSignedDownloadUrlForMessageArgs = {
  key: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
  isDownloadUrl?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFileSignedDownloadUrlForCommentArgs = {
  key: Scalars['String']['input'];
  commentId: Scalars['ID']['input'];
  isDownloadUrl?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMessageSearchArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMessageRankedSearchArgs = {
  params: SearchParams;
};

export type QuerySearchQueryArgs = {
  id: Scalars['ID']['input'];
  context?: InputMaybe<SearchQueryContext>;
};

export type QuerySearchQueriesArgs = {
  type?: SearchQueryType;
};

export type QueryTemplatesInTopicArgs = {
  topicId: Scalars['ID']['input'];
};

export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTasksArgs = {
  filter: TasksFilterInput;
  pagination: PaginationInput;
};

/** User subscription action type */
export enum UserActionType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED',
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
}

/** User status */
export enum UserStatus {
  /** User is active */
  Active = 'ACTIVE',
  /** User is deleted */
  Deleted = 'DELETED',
  /** User is deactivated */
  Deactivated = 'DEACTIVATED',
  /** User is not assigned */
  NotAssigned = 'NOT_ASSIGNED',
  /** User did not activate account or accept terms and conditions */
  ActivationPending = 'ACTIVATION_PENDING',
}

export type User = {
  __typename?: 'User';
  /** User mongo ID */
  id: Scalars['ID']['output'];
  /** Email of a user */
  email: Scalars['String']['output'];
  /** User first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** User last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** User image */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** User language */
  language: Locale;
  /** Target (translate) language */
  targetLanguage?: Maybe<TargetLanguage>;
  /** User localization */
  localization: Scalars['String']['output'];
  /** User timezone */
  timezone?: Maybe<Scalars['Int']['output']>;
  /** Array of hotels with related permissions (visible only for authenticated user in specific context) */
  hotelPermissions: Array<HotelPermissions>;
  /** User groups that contain this user as a member */
  userGroups: Array<UserGroup>;
  /** User status */
  status: UserStatus;
  /** Date of user creation */
  createdAt: Scalars['Date']['output'];
  /** Date of user last update */
  updatedAt: Scalars['Date']['output'];
  /** Last active selected hotel */
  selectedHotel?: Maybe<Scalars['String']['output']>;
  /** Whether or not the user has created any messages */
  hasMessages: Scalars['Boolean']['output'];
};

export type HotelPermissions = {
  __typename?: 'HotelPermissions';
  /** Hotel object */
  hotel: Hotel;
  /** Array of object-based roles */
  roles: Array<Scalars['String']['output']>;
  /** Array of user hotel permission */
  permissions: Array<Scalars['String']['output']>;
};

export type LoginRes = {
  __typename?: 'LoginRes';
  /** User object */
  user: User;
  /** Access token generated from 'code' */
  token: Scalars['String']['output'];
  /** Is the user logging in for the first time */
  isFirstLogin: Scalars['Boolean']['output'];
};

export type LogoutRes = {
  __typename?: 'LogoutRes';
  /** Status that indicates if the user is logged out */
  status: Scalars['Boolean']['output'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  /** User subscription action type */
  action: UserActionType;
  /** User object */
  user: User;
};

export type UserCredentials = {
  /** Username */
  username: Scalars['String']['input'];
  /** Password */
  password: Scalars['String']['input'];
};

export type UserUpdateInput = {
  /** Object-based roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Translation (target) language */
  targetLanguage?: InputMaybe<TargetLanguage>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation for user login with auth code */
  login: LoginRes;
  /** Mutation for user login with credentials */
  loginWithCredentials: LoginRes;
  /** Mutation for user logout */
  logout: LogoutRes;
  /** Activate a user */
  userActivate: User;
  /** Deactivate a user */
  userDeactivate: User;
  /** Update a user */
  userUpdate: User;
  /** User select hotel */
  userSelectHotel: User;
  /** Add user notifications */
  userNotificationsAdd: UserNotifications;
  /** Remove user notifications */
  userNotificationsRemove: UserNotifications;
  /** Update user notifications */
  userNotificationsUpdate: UserNotifications;
  /** Create user group */
  userGroupCreate: UserGroup;
  /** Update user group by the given ID and updated date */
  userGroupUpdate: UserGroup;
  /** Delete (soft) user group by the given ID */
  userGroupDelete: ActionResult;
  /** Update hotel */
  hotelUpdate: ActionResult;
  /** Enable or disable chat feature for the hotel */
  hotelSetChatFeature: ActionResult;
  /** Create a label for hotel */
  labelCreate: Label;
  /** Update label by the given label ID */
  labelUpdate: Label;
  /** Soft delete label by the given label ID */
  labelDelete: ActionResult;
  /** Create topic */
  topicCreate: Topic;
  /** Update topic by the given ID and updated data */
  topicUpdate: Topic;
  /** Delete (soft) topic by the given ID */
  topicDelete: ActionResult;
  /** Create message */
  messageCreate: Message;
  /** Update message */
  messageUpdate: Message;
  /** Delete message with given id (soft delete) */
  messageDelete: ActionResult;
  /** Update message status */
  messageStatusUpdate: Message;
  /** Archive message with a given ID */
  messageArchive: Message;
  /** Unarchive message with a given ID */
  messageUnarchive: Message;
  /** Subscribe the user's device to a push notification */
  pushNotificationSubscribe: PushNotification;
  /** Unsubscribe the user's device to a push notification */
  pushNotificationUnsubscribe: ActionResult;
  /** Create comment from message (messageID, user) */
  commentCreate: Comment;
  /** Update text comment by the given comment mongo ID */
  commentUpdate: Comment;
  /** Delete comment by the given comment mongo ID */
  commentDelete: ActionResult;
  /** Adding a new checklist to the message checklist */
  checklistCreateItem: Message;
  /** Update specific checklist element by the given message mongo ID, checklist item mongo ID and new data(text) */
  checklistUpdateItem: Message;
  /** Check or uncheck specific checklist element by the given message mongo ID, checklist item mongo ID */
  checklistCheckItem: Message;
  /** Remove checklist element by the given message mongo ID and checklist item mongo ID */
  checklistDeleteItem: Message;
  /** Reorder checklist by the given list of checklist mongo IDs and new positions */
  checklistReorder: Message;
  /** External message store */
  externalMessageStore: ActionResult;
  /** External message store */
  externalThreadUpdate: ActionResult;
  /** Add files to a message */
  filesAddToMessage: Message;
  /** Remove files from a given message */
  filesDeleteFromMessage: Message;
  /** Add files to a comment */
  filesAddToComment: Comment;
  /** Remove files from a given comment */
  filesDeleteFromComment: Comment;
  /** Mark messages by a given messages IDs as read by user from context */
  messagesRead: ActionResult;
  /** Create template */
  templateCreate: Template;
  /** Update template */
  templateUpdate: Template;
  /** Delete template by the given ID */
  templateDelete: ActionResult;
  /** Save recurring rule */
  recurringRuleSave: Template;
  /** Delete recurring rule */
  recurringRuleDelete: Template;
  /** Create ROQ user token */
  roqTokenCreate: RoqToken;
};

export type MutationLoginArgs = {
  code: Scalars['String']['input'];
};

export type MutationLoginWithCredentialsArgs = {
  credentials: UserCredentials;
};

export type MutationUserActivateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUserDeactivateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUserUpdateArgs = {
  id: Scalars['ID']['input'];
  data: UserUpdateInput;
};

export type MutationUserNotificationsAddArgs = {
  type: NotificationType;
  events: Array<NotificationEvent>;
};

export type MutationUserNotificationsRemoveArgs = {
  type: NotificationType;
  events: Array<NotificationEvent>;
};

export type MutationUserNotificationsUpdateArgs = {
  type: NotificationType;
  events: Array<NotificationEvent>;
};

export type MutationUserGroupCreateArgs = {
  data: UserGroupCreateInput;
};

export type MutationUserGroupUpdateArgs = {
  id: Scalars['ID']['input'];
  data: UserGroupUpdateInput;
};

export type MutationUserGroupDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationHotelUpdateArgs = {
  uuid: Scalars['String']['input'];
  data: HotelUpdateInput;
};

export type MutationHotelSetChatFeatureArgs = {
  uuid: Scalars['String']['input'];
  isChatEnabled: Scalars['Boolean']['input'];
};

export type MutationLabelCreateArgs = {
  data: LabelInput;
};

export type MutationLabelUpdateArgs = {
  id: Scalars['ID']['input'];
  data: LabelInput;
};

export type MutationLabelDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationTopicCreateArgs = {
  data: TopicCreateInput;
};

export type MutationTopicUpdateArgs = {
  id: Scalars['ID']['input'];
  data: TopicUpdateInput;
};

export type MutationTopicDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMessageCreateArgs = {
  data: MessageInput;
};

export type MutationMessageUpdateArgs = {
  id: Scalars['ID']['input'];
  data: MessageUpdateInput;
};

export type MutationMessageDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMessageStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: MessageStatus;
};

export type MutationMessageArchiveArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMessageUnarchiveArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPushNotificationSubscribeArgs = {
  token: Scalars['String']['input'];
  device: DeviceInput;
};

export type MutationPushNotificationUnsubscribeArgs = {
  token: Scalars['String']['input'];
};

export type MutationCommentCreateArgs = {
  data: CommentInput;
};

export type MutationCommentUpdateArgs = {
  id: Scalars['ID']['input'];
  data: CommentUpdateInput;
};

export type MutationCommentDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationChecklistCreateItemArgs = {
  messageId: Scalars['ID']['input'];
  data: ChecklistItemInput;
};

export type MutationChecklistUpdateItemArgs = {
  id: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  data: ChecklistUpdateItemInput;
};

export type MutationChecklistCheckItemArgs = {
  id: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  data: ChecklistCheckItemInput;
};

export type MutationChecklistDeleteItemArgs = {
  id: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};

export type MutationChecklistReorderArgs = {
  messageId: Scalars['ID']['input'];
  data: Array<ChecklistReorderInput>;
};

export type MutationExternalMessageStoreArgs = {
  data: ExternalMessageStoreInput;
};

export type MutationExternalThreadUpdateArgs = {
  threadId: Scalars['String']['input'];
  data: ExternalThreadUpdateInput;
};

export type MutationFilesAddToMessageArgs = {
  keys: Array<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

export type MutationFilesDeleteFromMessageArgs = {
  ids: Array<Scalars['ID']['input']>;
  messageId: Scalars['ID']['input'];
};

export type MutationFilesAddToCommentArgs = {
  keys: Array<Scalars['String']['input']>;
  commentId: Scalars['ID']['input'];
};

export type MutationFilesDeleteFromCommentArgs = {
  ids: Array<Scalars['ID']['input']>;
  commentId: Scalars['ID']['input'];
};

export type MutationMessagesReadArgs = {
  messageIds: Array<Scalars['ID']['input']>;
};

export type MutationTemplateCreateArgs = {
  data: TemplateInput;
};

export type MutationTemplateUpdateArgs = {
  id: Scalars['ID']['input'];
  data: TemplateUpdateInput;
};

export type MutationTemplateDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRecurringRuleSaveArgs = {
  templateId: Scalars['ID']['input'];
  data: RecurringRuleInput;
};

export type MutationRecurringRuleDeleteArgs = {
  templateId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscription for user changes */
  userInfo: UserInfo;
  /** Subscription listening if userGroup is changed for some hotel */
  userGroupInfo: UserGroupInfo;
  /** Subscription listening if label is changed for some hotel */
  labelInfo: LabelInfo;
  /** Subscription listening if topic is changed for some hotel */
  topicInfo: TopicInfo;
  /** Subscription listening the open topic */
  messageInfo: MessageInfo;
  /** Subscription listening the open message */
  commentInfo: CommentInfo;
  /** Subscription listening if template is changed for some hotel */
  templateInfo: TemplateInfo;
};

export type SubscriptionMessageInfoArgs = {
  topicId: Scalars['ID']['input'];
};

export type SubscriptionCommentInfoArgs = {
  messageId: Scalars['ID']['input'];
};

/** Notification events */
export enum NotificationEvent {
  TopicCreated = 'TOPIC__CREATED',
  TopicTitleUpdated = 'TOPIC__TITLE__UPDATED',
  TopicDescriptionUpdated = 'TOPIC__DESCRIPTION__UPDATED',
  TopicPrivacyUpdatedToPrivate = 'TOPIC__PRIVACY__UPDATED_TO_PRIVATE',
  TopicPrivacyUpdatedToPublic = 'TOPIC__PRIVACY__UPDATED_TO_PUBLIC',
  TopicMemberAdded = 'TOPIC__MEMBER__ADDED',
  TopicMemberRemoved = 'TOPIC__MEMBER__REMOVED',
  TopicGroupAdded = 'TOPIC__GROUP__ADDED',
  TopicGroupRemoved = 'TOPIC__GROUP__REMOVED',
  TopicDeleted = 'TOPIC__DELETED',
  UserFirstLogIn = 'USER__FIRST_LOG_IN',
  UserActivated = 'USER__ACTIVATED',
  UserDeactivated = 'USER__DEACTIVATED',
  UserRoleUpdated = 'USER__ROLE__UPDATED',
  UserGroupCreated = 'USER_GROUP__CREATED',
  UserGroupTitleUpdated = 'USER_GROUP__TITLE__UPDATED',
  UserGroupMemberAdded = 'USER_GROUP__MEMBER__ADDED',
  UserGroupMemberRemoved = 'USER_GROUP__MEMBER__REMOVED',
  UserGroupDeleted = 'USER_GROUP__DELETED',
  LabelCreated = 'LABEL__CREATED',
  LabelUpdated = 'LABEL__UPDATED',
  LabelDeleted = 'LABEL__DELETED',
  MessageCreated = 'MESSAGE__CREATED',
  MessageCreatedForAssignees = 'MESSAGE__CREATED__FOR_ASSIGNEES',
  MessageExternalCreated = 'MESSAGE__EXTERNAL_CREATED',
  MessageTitleUpdated = 'MESSAGE__TITLE__UPDATED',
  MessageDescriptionUpdated = 'MESSAGE__DESCRIPTION__UPDATED',
  MessageAssigneeAdded = 'MESSAGE__ASSIGNEE__ADDED',
  MessageAssigneeRemoved = 'MESSAGE__ASSIGNEE__REMOVED',
  MessageGroupAdded = 'MESSAGE__GROUP__ADDED',
  MessageGroupRemoved = 'MESSAGE__GROUP__REMOVED',
  MessageDueDateAdded = 'MESSAGE__DUE_DATE__ADDED',
  MessageDueDateUpdated = 'MESSAGE__DUE_DATE__UPDATED',
  MessageDueDateRemoved = 'MESSAGE__DUE_DATE__REMOVED',
  MessageRemindersUpdated = 'MESSAGE__REMINDERS__UPDATED',
  MessageStatusUpdated = 'MESSAGE__STATUS__UPDATED',
  ChecklistItemCreated = 'CHECKLIST_ITEM__CREATED',
  ChecklistItemChecked = 'CHECKLIST_ITEM__CHECKED',
  ChecklistItemUnchecked = 'CHECKLIST_ITEM__UNCHECKED',
  ChecklistItemDeleted = 'CHECKLIST_ITEM__DELETED',
  MessageLabelAdded = 'MESSAGE__LABEL__ADDED',
  MessageLabelRemoved = 'MESSAGE__LABEL__REMOVED',
  MessageFileAdded = 'MESSAGE__FILE__ADDED',
  MessageFileDeleted = 'MESSAGE__FILE__DELETED',
  MessageTitleUpdatedForAssignees = 'MESSAGE__TITLE__UPDATED__FOR_ASSIGNEES',
  MessageDescriptionUpdatedForAssignees = 'MESSAGE__DESCRIPTION__UPDATED__FOR_ASSIGNEES',
  MessageAssigneeAddedForAssignees = 'MESSAGE__ASSIGNEE__ADDED__FOR_ASSIGNEES',
  MessageAssigneeRemovedForAssignees = 'MESSAGE__ASSIGNEE__REMOVED__FOR_ASSIGNEES',
  MessageGroupAddedForAssignees = 'MESSAGE__GROUP__ADDED__FOR_ASSIGNEES',
  MessageGroupRemovedForAssignees = 'MESSAGE__GROUP__REMOVED__FOR_ASSIGNEES',
  MessageDueDateAddedForAssignees = 'MESSAGE__DUE_DATE__ADDED__FOR_ASSIGNEES',
  MessageDueDateUpdatedForAssignees = 'MESSAGE__DUE_DATE__UPDATED__FOR_ASSIGNEES',
  MessageDueDateRemovedForAssignees = 'MESSAGE__DUE_DATE__REMOVED__FOR_ASSIGNEES',
  MessageRemindersUpdatedForAssignees = 'MESSAGE__REMINDERS__UPDATED__FOR_ASSIGNEES',
  MessageStatusUpdatedForAssignees = 'MESSAGE__STATUS__UPDATED__FOR_ASSIGNEES',
  ChecklistItemCreatedForAssignees = 'CHECKLIST_ITEM__CREATED__FOR_ASSIGNEES',
  ChecklistItemCheckedForAssignees = 'CHECKLIST_ITEM__CHECKED__FOR_ASSIGNEES',
  ChecklistItemUncheckedForAssignees = 'CHECKLIST_ITEM__UNCHECKED__FOR_ASSIGNEES',
  ChecklistItemDeletedForAssignees = 'CHECKLIST_ITEM__DELETED__FOR_ASSIGNEES',
  MessageLabelAddedForAssignees = 'MESSAGE__LABEL__ADDED__FOR_ASSIGNEES',
  MessageLabelRemovedForAssignees = 'MESSAGE__LABEL__REMOVED__FOR_ASSIGNEES',
  MessageFileAddedForAssignees = 'MESSAGE__FILE__ADDED__FOR_ASSIGNEES',
  MessageFileDeletedForAssignees = 'MESSAGE__FILE__DELETED__FOR_ASSIGNEES',
  MessageTitleUpdatedForCreator = 'MESSAGE__TITLE__UPDATED__FOR_CREATOR',
  MessageDescriptionUpdatedForCreator = 'MESSAGE__DESCRIPTION__UPDATED__FOR_CREATOR',
  MessageAssigneeAddedForCreator = 'MESSAGE__ASSIGNEE__ADDED__FOR_CREATOR',
  MessageAssigneeRemovedForCreator = 'MESSAGE__ASSIGNEE__REMOVED__FOR_CREATOR',
  MessageGroupAddedForCreator = 'MESSAGE__GROUP__ADDED__FOR_CREATOR',
  MessageGroupRemovedForCreator = 'MESSAGE__GROUP__REMOVED__FOR_CREATOR',
  MessageDueDateAddedForCreator = 'MESSAGE__DUE_DATE__ADDED__FOR_CREATOR',
  MessageDueDateUpdatedForCreator = 'MESSAGE__DUE_DATE__UPDATED__FOR_CREATOR',
  MessageDueDateRemovedForCreator = 'MESSAGE__DUE_DATE__REMOVED__FOR_CREATOR',
  MessageRemindersUpdatedForCreator = 'MESSAGE__REMINDERS__UPDATED__FOR_CREATOR',
  MessageStatusUpdatedForCreator = 'MESSAGE__STATUS__UPDATED__FOR_CREATOR',
  ChecklistItemCreatedForCreator = 'CHECKLIST_ITEM__CREATED__FOR_CREATOR',
  ChecklistItemCheckedForCreator = 'CHECKLIST_ITEM__CHECKED__FOR_CREATOR',
  ChecklistItemUncheckedForCreator = 'CHECKLIST_ITEM__UNCHECKED__FOR_CREATOR',
  ChecklistItemDeletedForCreator = 'CHECKLIST_ITEM__DELETED__FOR_CREATOR',
  MessageLabelAddedForCreator = 'MESSAGE__LABEL__ADDED__FOR_CREATOR',
  MessageLabelRemovedForCreator = 'MESSAGE__LABEL__REMOVED__FOR_CREATOR',
  MessageFileAddedForCreator = 'MESSAGE__FILE__ADDED__FOR_CREATOR',
  MessageFileDeletedForCreator = 'MESSAGE__FILE__DELETED__FOR_CREATOR',
  MessageArchived = 'MESSAGE__ARCHIVED',
  MessageArchivedForAssignees = 'MESSAGE__ARCHIVED__FOR_ASSIGNEES',
  MessageArchivedForCreator = 'MESSAGE__ARCHIVED__FOR_CREATOR',
  MessageUnarchived = 'MESSAGE__UNARCHIVED',
  MessageUnarchivedForAssignees = 'MESSAGE__UNARCHIVED__FOR_ASSIGNEES',
  MessageUnarchivedForCreator = 'MESSAGE__UNARCHIVED__FOR_CREATOR',
  MessageDeleted = 'MESSAGE__DELETED',
  MessageDeletedForAssignees = 'MESSAGE__DELETED__FOR_ASSIGNEES',
  MessageDeletedForCreator = 'MESSAGE__DELETED__FOR_CREATOR',
  CommentCreated = 'COMMENT__CREATED',
  CommentCreatedForAssignees = 'COMMENT__CREATED__FOR_ASSIGNEES',
  CommentCreatedForCreator = 'COMMENT__CREATED__FOR_CREATOR',
  CommentExternalCreated = 'COMMENT__EXTERNAL_CREATED',
  CommentExternalCreatedForAssignees = 'COMMENT__EXTERNAL_CREATED__FOR_ASSIGNEES',
  MessageRemindersNotification = 'MESSAGE__REMINDERS__NOTIFICATION',
  MessageRemindersNotificationForAssignees = 'MESSAGE__REMINDERS__NOTIFICATION__FOR_ASSIGNEES',
  MessageRemindersNotificationForCreator = 'MESSAGE__REMINDERS__NOTIFICATION__FOR_CREATOR',
  ChatMessageCreated = 'CHAT__MESSAGE__CREATED',
}

/** Notification types */
export enum NotificationType {
  Email = 'EMAIL',
  Push = 'PUSH',
}

/** Notification groups */
export enum NotificationGroup {
  Topics = 'TOPICS',
  Users = 'USERS',
  UserGroups = 'USER_GROUPS',
  Labels = 'LABELS',
  MessageIsCreated = 'MESSAGE_IS_CREATED',
  MessageIsUpdated = 'MESSAGE_IS_UPDATED',
  MessageIsUpdatedForAssignees = 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES',
  MessageIsUpdatedForCreator = 'MESSAGE_IS_UPDATED__FOR_CREATOR',
  MessageIsArchived = 'MESSAGE_IS_ARCHIVED',
  MessageIsDeleted = 'MESSAGE_IS_DELETED',
  Comments = 'COMMENTS',
  Reminders = 'REMINDERS',
  Chat = 'CHAT',
}

export type GroupedNotifications = {
  __typename?: 'GroupedNotifications';
  TOPICS: Array<NotificationEvent>;
  USERS: Array<NotificationEvent>;
  USER_GROUPS: Array<NotificationEvent>;
  LABELS: Array<NotificationEvent>;
  MESSAGE_IS_CREATED: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
  MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
  MESSAGE_IS_DELETED: Array<NotificationEvent>;
  COMMENTS: Array<NotificationEvent>;
  REMINDERS: Array<NotificationEvent>;
  CHAT: Array<NotificationEvent>;
};

export type UserNotifications = {
  __typename?: 'UserNotifications';
  type: NotificationType;
  available: GroupedNotifications;
  default: GroupedNotifications;
  selected: GroupedNotifications;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  /** User group mongo ID */
  id: Scalars['ID']['output'];
  /** Hotel where this group belong */
  hotel: Hotel;
  /** User group title */
  title: Scalars['String']['output'];
  /** Members of the user group */
  members: Array<User>;
  /** Date of user group creation */
  createdAt: Scalars['Date']['output'];
  /** Date of user group last update */
  updatedAt: Scalars['Date']['output'];
};

export type UserGroupInfo = {
  __typename?: 'UserGroupInfo';
  /** UserGroup object */
  userGroup: UserGroup;
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
};

export type UserGroupCreateInput = {
  /** Title of a new user group */
  title: Scalars['String']['input'];
  /** List of member IDs of a new user group */
  members?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserGroupUpdateInput = {
  /** New title for a user group */
  title?: InputMaybe<Scalars['String']['input']>;
  /** List of member IDs of a user group */
  members?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Hotel = {
  __typename?: 'Hotel';
  /** Hotel mongo ID */
  id: Scalars['ID']['output'];
  /** Hotel UUID */
  uuid: Scalars['String']['output'];
  /** Hotel name */
  name: Scalars['String']['output'];
  /** Hotel image */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Hotel status */
  status: HotelStatus;
  /** Is testing object */
  isTesting: Scalars['Boolean']['output'];
  /** Is chat enabled */
  isChatEnabled: Scalars['Boolean']['output'];
};

export type NumberOfHotelUsersForAnalytics = {
  __typename?: 'NumberOfHotelUsersForAnalytics';
  /** Total number of users */
  total: Scalars['Int']['output'];
  /** Number of active users */
  active: Scalars['Int']['output'];
  /** Number of inactive users */
  inactive: Scalars['Int']['output'];
};

export type HotelForAnalytics = {
  __typename?: 'HotelForAnalytics';
  /** Hotel mongo ID */
  id: Scalars['ID']['output'];
  /** GF object ID */
  objectId: Scalars['String']['output'];
  /** Hotel UUID */
  uuid: Scalars['String']['output'];
  /** Hotel name */
  name: Scalars['String']['output'];
  /** Hotel image */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Hotel status */
  status: HotelStatus;
  /** Is testing object */
  isTesting: Scalars['Boolean']['output'];
  /** Is chat enabled */
  isChatEnabled: Scalars['Boolean']['output'];
  /** Number of users */
  numberOfUsers: NumberOfHotelUsersForAnalytics;
};

/** Hotel statuses */
export enum HotelStatus {
  Inactive = 'INACTIVE',
  Basic = 'BASIC',
  Premium = 'PREMIUM',
  Deleted = 'DELETED',
}

/** Hotel update input */
export type HotelUpdateInput = {
  /** GF object ID */
  objectId: Scalars['String']['input'];
  /** Hotel name */
  name: Scalars['String']['input'];
  /** Hotel image */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Hotel status */
  status: HotelStatus;
  /** Is testing object */
  isTesting: Scalars['Boolean']['input'];
  /** Hotel language */
  language?: InputMaybe<Scalars['String']['input']>;
};

export type LabelInput = {
  /** Label text */
  text: Scalars['String']['input'];
  /** Label color (hexadecimal representation) */
  color: Scalars['Color']['input'];
};

export type Label = {
  __typename?: 'Label';
  /** Label mongo ID */
  id: Scalars['ID']['output'];
  /** Label text */
  text: Scalars['String']['output'];
  /** Label color (hexadecimal representation) */
  color: Scalars['Color']['output'];
  /** Database hotel for witch we store label */
  hotel: Hotel;
  /** Date of label creation */
  createdAt: Scalars['Date']['output'];
  /** Date of label last update */
  updatedAt: Scalars['Date']['output'];
};

export type LabelInfo = {
  __typename?: 'LabelInfo';
  /** Label object */
  label: Label;
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
};

export enum TopicType {
  /** Does not contain external messages */
  Basic = 'BASIC',
  /** Contains custom form message */
  Custom = 'CUSTOM',
  /** Contains concierge form message */
  Concierge = 'CONCIERGE',
}

export type Topic = {
  __typename?: 'Topic';
  /** Topic mongo ID */
  id: Scalars['ID']['output'];
  /** Hotel where this topic belong */
  hotel: Hotel;
  /** Topic title */
  title: Scalars['String']['output'];
  /** Topic description */
  description?: Maybe<Scalars['String']['output']>;
  /** Flag which indicates whether the topic is private or public */
  isPrivate: Scalars['Boolean']['output'];
  /** Creator of the topic */
  creator?: Maybe<User>;
  /** Assigned users (relevant if the topic is private) */
  members: Array<User>;
  /** Assigned userGroups (relevant if the topic is private) */
  groups: Array<UserGroup>;
  /** Date of topic creation */
  createdAt: Scalars['Date']['output'];
  /** Date of topic last update */
  updatedAt: Scalars['Date']['output'];
  /** Topic type */
  type?: Maybe<TopicType>;
  /** Number of unread messages */
  unreadMessages: Scalars['Int']['output'];
};

export type TopicInfo = {
  __typename?: 'TopicInfo';
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
  /** Topic mongo ID */
  id: Scalars['ID']['output'];
  /** Topic model (available only for CREATED and UPDATED actions) */
  topic?: Maybe<Topic>;
};

export type TopicCreateInput = {
  /** Title of a new topic */
  title: Scalars['String']['input'];
  /** Description of a new topic */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Flag which indicates whether the topic is private or public */
  isPrivate: Scalars['Boolean']['input'];
  /** User IDs of a new topic */
  members?: InputMaybe<Array<Scalars['String']['input']>>;
  /** UserGroup IDs of a new topic */
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TopicUpdateInput = {
  /** New title for topic */
  title?: InputMaybe<Scalars['String']['input']>;
  /** New description for topic */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Flag which indicates whether the topic is private or public */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Topic members if topic is private */
  members?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Topic groups if topic is private */
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReminderInput = {
  /** The amount of minutes, hours, days, etc. */
  amount: Scalars['Int']['input'];
  /** The time unit: minutes, hours, days, etc. */
  unit: TimeUnit;
};

export type Reminder = {
  __typename?: 'Reminder';
  /** The amount of minutes, hours, days, etc. */
  amount: Scalars['Int']['output'];
  /** The time unit: minutes, hours, days, etc. */
  unit: TimeUnit;
};

export type ReminderOption = {
  __typename?: 'ReminderOption';
  /** The amount of minutes, hours, days, etc. */
  amount: Scalars['Int']['output'];
  /** The time unit: minutes, hours, days, etc. */
  unit: TimeUnit;
  /** Is this the default option? */
  default: Scalars['Boolean']['output'];
};

/** Message type */
export enum MessageType {
  PlainText = 'PLAIN_TEXT',
  ConciergeForm = 'CONCIERGE_FORM',
}

/** Message status */
export enum MessageStatus {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
}

/** Message creator type */
export enum MessageCreatorType {
  HotelboardUser = 'HOTELBOARD_USER',
  HotelboardRecurring = 'HOTELBOARD_RECURRING',
  Guest = 'GUEST',
}

export type MessageInput = {
  /** Title of a message */
  title: Scalars['String']['input'];
  /** Text of a message */
  text: Scalars['String']['input'];
  /** The topic of a message */
  topic: Scalars['String']['input'];
  /** Message due date */
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  /** Reminders */
  reminders?: InputMaybe<Array<ReminderInput>>;
  /** Message labels */
  labels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Message checklist */
  checklist?: InputMaybe<Array<ChecklistItemMessageCreateInput>>;
  /** File keys that should be used as attachments */
  files?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Assigned users */
  assignedMembers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assigned user groups */
  assignedGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MessageUpdateInput = {
  /** Title of a message */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Text of a message */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Message due date */
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  /** Reminders */
  reminders?: InputMaybe<Array<ReminderInput>>;
  /** Message labels */
  labels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assigned users */
  assignedMembers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assigned user groups */
  assignedGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** External thread */
export type Thread = {
  __typename?: 'Thread';
  /** Thread ID (UUID) */
  id: Scalars['String']['output'];
  /** Thread title */
  title: Scalars['String']['output'];
  /** Submission language */
  submissionLanguage: Scalars['String']['output'];
  /** Is thread supporting replies */
  isSupportingReplies: Scalars['Boolean']['output'];
  /** Is thread archived by guest */
  isArchivedByGuest: Scalars['Boolean']['output'];
};

export type MessageTranslation = {
  __typename?: 'MessageTranslation';
  /** Translated message title */
  title: Scalars['String']['output'];
  /** Translated message text */
  text: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  /** Message mongo ID */
  id: Scalars['ID']['output'];
  /** Message title */
  title: Scalars['String']['output'];
  /** Message text */
  text: Scalars['String']['output'];
  /** Message translation */
  translation: MessageTranslation;
  /** Creator of a message */
  creator?: Maybe<User>;
  /** Topic which contains this message */
  topic: Topic;
  /** Assigned users */
  assignedMembers: Array<User>;
  /** Assigned user groups */
  assignedGroups: Array<UserGroup>;
  /** Indicates the message type */
  type: MessageType;
  /** Message creator type */
  creatorType: MessageCreatorType;
  /** External thread */
  thread?: Maybe<Thread>;
  /** Is waiting for reply (for external communication) */
  isAwaitingReply: Scalars['Boolean']['output'];
  /** Message labels */
  labels: Array<Label>;
  /** Message due date */
  dueDate?: Maybe<Scalars['Date']['output']>;
  /** Message status */
  status?: Maybe<MessageStatus>;
  /** Message checklist */
  checklist: Array<ChecklistItem>;
  /** Message attachments */
  files: Array<File>;
  /** Message reminders */
  reminders: Array<Reminder>;
  /** Is message archived */
  isArchived: Scalars['Boolean']['output'];
  /** Date of message creation */
  createdAt: Scalars['Date']['output'];
  /** Date of the message last update */
  updatedAt: Scalars['Date']['output'];
  /** Number of comments in a message */
  numberOfComments: Scalars['Int']['output'];
  /** List of user read objects */
  readBy: Array<User>;
  /** Number of read by percentage */
  readPercentage: Scalars['Int']['output'];
  /** Message is read/unread */
  isSeen: Scalars['Boolean']['output'];
};

export type MessageInfo = {
  __typename?: 'MessageInfo';
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
  /** Message object */
  message: Message;
};

/** Device input type */
export type DeviceInput = {
  /** Device mongo ID */
  id: Scalars['ID']['input'];
  /** Device type */
  type: Scalars['String']['input'];
  /** Device name */
  name: Scalars['String']['input'];
};

/** PushNotification type */
export type PushNotification = {
  __typename?: 'PushNotification';
  /** Push notification mongo ID */
  id: Scalars['ID']['output'];
  /** Subscription token */
  token: Scalars['String']['output'];
  /** Device information */
  device: Device;
};

/** Device type */
export type Device = {
  __typename?: 'Device';
  /** Device ID */
  id: Scalars['ID']['output'];
  /** Device type */
  type: Scalars['String']['output'];
  /** Device name */
  name: Scalars['String']['output'];
};

export type CommentInput = {
  /** Comment text the user sent in message reply */
  text: Scalars['String']['input'];
  /** Mongo ID of a message which contains this comment */
  message: Scalars['ID']['input'];
  /** Should comment be forwarder to the external app */
  isForExternal?: InputMaybe<Scalars['Boolean']['input']>;
  /** File keys that should be used as attachments */
  files?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CommentUpdateInput = {
  /** Comment text the user sent in message reply */
  text: Scalars['String']['input'];
};

export type CommentTranslation = {
  __typename?: 'CommentTranslation';
  /** Translated comment text */
  text: Scalars['String']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  /** Comment mongo ID */
  id: Scalars['ID']['output'];
  /** Comment text */
  text: Scalars['String']['output'];
  /** Comment translation */
  translation: CommentTranslation;
  /** User that created the comment */
  creator?: Maybe<User>;
  /** Parent message of this comment */
  message: Message;
  /** Date of comment creation */
  createdAt: Scalars['Date']['output'];
  /** Date of comment update */
  updatedAt: Scalars['Date']['output'];
  /** Is comment forwarded to the external app */
  isForExternal: Scalars['Boolean']['output'];
  /** Comment attachments */
  files: Array<File>;
};

export type CommentInfo = {
  __typename?: 'CommentInfo';
  /** Comment object */
  comment: Comment;
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
};

export type ChecklistUpdateItemInput = {
  /** Checklist item text */
  text: Scalars['String']['input'];
};

export type ChecklistCheckItemInput = {
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['input'];
};

export type ChecklistItemInput = {
  /** Checklist item text */
  text: Scalars['String']['input'];
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['input'];
  /** Position of a checklist item */
  position: Scalars['Int']['input'];
};

export type ChecklistItemMessageCreateInput = {
  /** Checklist item text */
  text: Scalars['String']['input'];
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['input'];
};

export type ChecklistReorderInput = {
  /** Checklist mongo ID of checklist item that changes position */
  checklistId: Scalars['ID']['input'];
  /** New position of a checklist item */
  newPosition: Scalars['Int']['input'];
};

export type ChecklistItemTranslation = {
  __typename?: 'ChecklistItemTranslation';
  /** Translated checklist item text */
  text: Scalars['String']['output'];
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** Checklist mongo ID */
  id: Scalars['ID']['output'];
  /** Checklist item position */
  position: Scalars['Int']['output'];
  /** Checklist item text */
  text: Scalars['String']['output'];
  /** Checklist item translation */
  translation: ChecklistItemTranslation;
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['output'];
  /** The date and time when is last checked/unchecked action executed */
  updatedAt: Scalars['Date']['output'];
};

/** Thread meta input */
export type ThreadMetaInput = {
  /** External thread title */
  title: Scalars['String']['input'];
  /** Submission language */
  submissionLanguage: Scalars['String']['input'];
  /** Is thread supporting replies */
  isSupportingReplies: Scalars['Boolean']['input'];
  /** Is thread archived by guest */
  isArchivedByGuest: Scalars['Boolean']['input'];
};

/** External message store input */
export type ExternalMessageStoreInput = {
  /** Message ID */
  messageId: Scalars['Int']['input'];
  /** Thread ID (UUID) */
  threadId: Scalars['String']['input'];
  /** Message body */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Message meta (eg. serialized json with attachments and eg. concierge form data) */
  meta: Scalars['String']['input'];
  /** Message type */
  type: MessageType;
  /** Message creation timestamp */
  receivedAt: Scalars['Date']['input'];
  /** Thread meta (nullable for guest replies) */
  threadMeta?: InputMaybe<ThreadMetaInput>;
};

/** External thread update input */
export type ExternalThreadUpdateInput = {
  /** Is thread archived by guest */
  isArchivedByGuest: Scalars['Boolean']['input'];
};

export type File = {
  __typename?: 'File';
  /** File ID */
  id: Scalars['String']['output'];
  /** File key from S3 */
  key: Scalars['String']['output'];
  /** File name extracted from file key */
  name: Scalars['String']['output'];
  /** File extension (jpg, pdf...) */
  extension: Scalars['String']['output'];
  /** URL to the thumbnail version of the image */
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type SearchInfo = {
  __typename?: 'SearchInfo';
  /** The flag that indicates the total number of results */
  total: Scalars['Int']['output'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  /** Search result nodes */
  nodes: Array<Message>;
  /** Additional info of search result */
  searchInfo: SearchInfo;
};

export type SearchParams = {
  /** Search string from the user input */
  queryString: Scalars['String']['input'];
  /** Results offset */
  from: Scalars['Int']['input'];
  /** Number of results to return */
  size: Scalars['Int']['input'];
};

/** Search query type */
export enum SearchQueryType {
  SmartView = 'SMART_VIEW',
  QuickFilter = 'QUICK_FILTER',
  DashboardKpi = 'DASHBOARD_KPI',
}

/** Dashboard KPI type */
export enum DashboardKpiType {
  Task = 'TASK',
  Request = 'REQUEST',
  Concierge = 'CONCIERGE',
}

export type SearchQueryContext = {
  /** Topic mongo ID */
  topicId?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchQueryTitle = {
  __typename?: 'SearchQueryTitle';
  /** Search query de title */
  de: Scalars['String']['output'];
  /** Search query en title */
  en: Scalars['String']['output'];
  /** Search query fr title */
  fr: Scalars['String']['output'];
  /** Search query it title */
  it: Scalars['String']['output'];
  /** Search query nl title */
  nl: Scalars['String']['output'];
};

export type SearchQuery = {
  __typename?: 'SearchQuery';
  /** Search query mongo ID */
  id: Scalars['ID']['output'];
  /** Search query title */
  title: SearchQueryTitle;
  /** The 'query' part of an ES query */
  query: Scalars['String']['output'];
  /** The 'sort' part of an ES query */
  sort?: Maybe<Scalars['String']['output']>;
  /** Search query type */
  type: SearchQueryType;
  /** Dashboard KPI type */
  dashboardKpiType?: Maybe<DashboardKpiType>;
  /** Related SearchQuery ID */
  relatedSearchQueryId?: Maybe<Scalars['ID']['output']>;
};

export type DashboardKpi = {
  __typename?: 'DashboardKpi';
  /** Search query of search result */
  searchQuery: SearchQuery;
  /** Additional info of search result */
  searchInfo: SearchInfo;
};

export type ChecklistItemTemplateCreateInput = {
  /** Checklist item text */
  text: Scalars['String']['input'];
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['input'];
};

export type ChecklistItemTemplateUpdateInput = {
  /** Checklist item ID (optional) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Checklist item text */
  text: Scalars['String']['input'];
  /** Flag that indicates that checkbox checked (done) */
  checked: Scalars['Boolean']['input'];
};

export type TemplateInput = {
  /** Template title */
  title: Scalars['String']['input'];
  /** Template text */
  text: Scalars['String']['input'];
  /** Topic in which the template can be used */
  topic: Scalars['String']['input'];
  /** Assigned users IDs */
  assignedMembers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assigned user groups IDs */
  assignedGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Template labels IDs */
  labels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Template checklist */
  checklist?: InputMaybe<Array<ChecklistItemTemplateCreateInput>>;
  /** Template due date offset */
  dueDateOffset?: InputMaybe<DateOffsetInput>;
  /** Reminders */
  reminders?: InputMaybe<Array<ReminderInput>>;
};

export type TemplateUpdateInput = {
  /** Template title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Template text */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Assigned users IDs */
  assignedMembers?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assigned user groups IDs */
  assignedGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Template labels IDs */
  labels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Template checklist */
  checklist?: InputMaybe<Array<ChecklistItemTemplateUpdateInput>>;
  /** Template due date offset */
  dueDateOffset?: InputMaybe<DateOffsetInput>;
  /** Reminders */
  reminders?: InputMaybe<Array<ReminderInput>>;
};

export type DateOffsetInput = {
  /** The amount of minutes, hours, etc. */
  amount: Scalars['Int']['input'];
  /** The time unit: minutes, hours, etc. */
  unit: TimeUnit;
};

export type DateOffset = {
  __typename?: 'DateOffset';
  /** The amount of minutes, hours, days, etc. */
  amount: Scalars['Int']['output'];
  /** The time unit: minutes, hours, days, etc. */
  unit: TimeUnit;
};

export type Template = {
  __typename?: 'Template';
  /** Template mongo ID */
  id: Scalars['ID']['output'];
  /** Template title */
  title: Scalars['String']['output'];
  /** Template text */
  text: Scalars['String']['output'];
  /** Creator of a template */
  creator: User;
  /** Topic in which the template can be used */
  topic: Topic;
  /** Assigned users */
  assignedMembers: Array<User>;
  /** Assigned user groups */
  assignedGroups: Array<UserGroup>;
  /** Template labels */
  labels: Array<Label>;
  /** Template checklist */
  checklist: Array<ChecklistItem>;
  /** Template due date offset */
  dueDateOffset?: Maybe<DateOffset>;
  /** Message reminders */
  reminders: Array<Reminder>;
  /** Template recurring rule */
  recurringRule?: Maybe<RecurringRule>;
  /** Date of message creation */
  createdAt: Scalars['Date']['output'];
  /** Date of the message last update */
  updatedAt: Scalars['Date']['output'];
};

export type TemplateInfo = {
  __typename?: 'TemplateInfo';
  /** Template object */
  template: Template;
  /** Subscription action type. It can be CREATED, UPDATED or DELETED */
  action: ActionType;
};

export type RecurringRule = {
  __typename?: 'RecurringRule';
  /** Recurring rule mongo ID */
  id: Scalars['ID']['output'];
  /** Stringified rules */
  rule: Scalars['String']['output'];
  /** Date of message creation */
  createdAt: Scalars['Date']['output'];
  /** Date of the message last update */
  updatedAt: Scalars['Date']['output'];
};

export type RecurringRuleInput = {
  /** Stringified rules */
  rule: Scalars['String']['input'];
};

/** Reservation status */
export type ReservationStatus = {
  __typename?: 'ReservationStatus';
  /** Hotel ID */
  id: Scalars['ID']['output'];
  /** Indicates if the reservation is connected or not */
  connected: Scalars['Boolean']['output'];
};

/** Guest nested object received from HCX-API */
export type Guest = {
  __typename?: 'Guest';
  id: Scalars['Int']['output'];
  uniqueId: Scalars['String']['output'];
  surname?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  vipValue?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  addressLine?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

/** Connected guid nested object received from HCX-API */
export type ConnectedGuid = {
  __typename?: 'ConnectedGuid';
  guid: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['String']['output']>;
};

/** Reservation object received from HCX-API */
export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['Int']['output'];
  requestorId: Scalars['String']['output'];
  uniqueId: Scalars['String']['output'];
  stayStart: Scalars['String']['output'];
  stayEnd: Scalars['String']['output'];
  resStatus: Scalars['String']['output'];
  hotelCode: Scalars['String']['output'];
  resIdType?: Maybe<Scalars['String']['output']>;
  resIdValue: Scalars['String']['output'];
  resIdSource?: Maybe<Scalars['String']['output']>;
  otaUpdatedAt: Scalars['String']['output'];
  apiType: Scalars['String']['output'];
  created_at?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  guestCount: Scalars['Int']['output'];
  guests: Array<Guest>;
  connectedGuids: Array<ConnectedGuid>;
};

export type RoqToken = {
  __typename?: 'RoqToken';
  token: Scalars['String']['output'];
};

export type TasksFilterInput = {
  topicId: Scalars['ID']['input'];
  status: MessageStatus;
  members?: InputMaybe<Array<Scalars['ID']['input']>>;
  groups?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AttachmentBasicFragment = {
  __typename?: 'File';
  id: string;
  key: string;
  extension: string;
  name: string;
  thumbnail?: string | null;
};

export type ChecklistItemBasicFragment = {
  __typename?: 'ChecklistItem';
  checked: boolean;
  id: string;
  text: string;
};

export type CommentFullFragment = {
  __typename?: 'Comment';
  id: string;
  createdAt: string;
  isForExternal: boolean;
  text: string;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  } | null;
  translation?: { __typename?: 'CommentTranslation'; text: string };
  message: { __typename?: 'Message'; id: string };
};

export type LabelBasicFragment = {
  __typename?: 'Label';
  id: string;
  color: string;
  text: string;
};

export type GroupedNotificationsFragment = {
  __typename?: 'GroupedNotifications';
  TOPICS: Array<NotificationEvent>;
  USERS: Array<NotificationEvent>;
  USER_GROUPS: Array<NotificationEvent>;
  LABELS: Array<NotificationEvent>;
  MESSAGE_IS_CREATED: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
  MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
  MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
  MESSAGE_IS_DELETED: Array<NotificationEvent>;
  REMINDERS: Array<NotificationEvent>;
  COMMENTS: Array<NotificationEvent>;
  CHAT: Array<NotificationEvent>;
};

export type ReminderItemBasicFragment = {
  __typename?: 'Reminder';
  amount: number;
  unit: TimeUnit;
};

export type ReservationBasicFragment = {
  __typename?: 'Reservation';
  id: number;
  resStatus: string;
  stayEnd: string;
  stayStart: string;
  uniqueId: string;
  connectedGuids: Array<{ __typename?: 'ConnectedGuid'; id: string }>;
  guests: Array<{
    __typename?: 'Guest';
    givenName?: string | null;
    id: number;
    surname?: string | null;
  }>;
};

export type SearchQueryBasicFragment = {
  __typename?: 'SearchQuery';
  id: string;
  title: {
    __typename?: 'SearchQueryTitle';
    de: string;
    en: string;
    fr: string;
    it: string;
    nl: string;
  };
};

export type CommentCreateMutationVariables = Exact<{
  text: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
  isForExternal: Scalars['Boolean']['input'];
  includeTranslations?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CommentCreateMutation = {
  __typename?: 'Mutation';
  commentCreate: {
    __typename?: 'Comment';
    id: string;
    createdAt: string;
    isForExternal: boolean;
    text: string;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    } | null;
    translation?: { __typename?: 'CommentTranslation'; text: string };
    message: { __typename?: 'Message'; id: string };
  };
};

export type LoginMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginRes';
    isFirstLogin: boolean;
    token: string;
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      language: Locale;
      imageUrl?: string | null;
      timezone?: number | null;
      hotelPermissions: Array<{
        __typename?: 'HotelPermissions';
        permissions: Array<string>;
        hotel: {
          __typename?: 'Hotel';
          id: string;
          uuid: string;
          name: string;
          imageUrl?: string | null;
        };
      }>;
    };
  };
};

export type UserNotificationsAddMutationVariables = Exact<{
  type: NotificationType;
  events: Array<NotificationEvent> | NotificationEvent;
}>;

export type UserNotificationsAddMutation = {
  __typename?: 'Mutation';
  userNotificationsAdd: {
    __typename?: 'UserNotifications';
    type: NotificationType;
    available: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    default: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    selected: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
  };
};

export type GuestBasicFragment = {
  __typename?: 'Guest';
  givenName?: string | null;
  id: number;
  surname?: string | null;
};

export type UserNotificationsUpdateMutationVariables = Exact<{
  type: NotificationType;
  events: Array<NotificationEvent> | NotificationEvent;
}>;

export type UserNotificationsUpdateMutation = {
  __typename?: 'Mutation';
  userNotificationsUpdate: {
    __typename?: 'UserNotifications';
    type: NotificationType;
    available: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    default: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    selected: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: { __typename?: 'LogoutRes'; status: boolean };
};

export type CommentInfoSubscriptionVariables = Exact<{
  messageId: Scalars['ID']['input'];
  includeTranslations?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CommentInfoSubscription = {
  __typename?: 'Subscription';
  commentInfo: {
    __typename?: 'CommentInfo';
    action: ActionType;
    comment: {
      __typename?: 'Comment';
      id: string;
      createdAt: string;
      isForExternal: boolean;
      text: string;
      creator?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      } | null;
      translation?: { __typename?: 'CommentTranslation'; text: string };
      message: { __typename?: 'Message'; id: string };
    };
  };
};

export type HotelPermissionsFragment = {
  __typename?: 'User';
  hotelPermissions: Array<{
    __typename?: 'HotelPermissions';
    permissions: Array<string>;
    roles: Array<string>;
    hotel: {
      __typename?: 'Hotel';
      id: string;
      isChatEnabled: boolean;
      uuid: string;
      name: string;
      imageUrl?: string | null;
      isTesting: boolean;
      status: HotelStatus;
    };
  }>;
};

export type LabelInfoSubscriptionVariables = Exact<{ [key: string]: never }>;

export type LabelInfoSubscription = {
  __typename?: 'Subscription';
  labelInfo: {
    __typename?: 'LabelInfo';
    action: ActionType;
    label: { __typename?: 'Label'; id: string; color: string; text: string };
  };
};

export type TemplateInfoSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TemplateInfoSubscription = {
  __typename?: 'Subscription';
  templateInfo: {
    __typename?: 'TemplateInfo';
    action: ActionType;
    template: {
      __typename?: 'Template';
      id: string;
      text: string;
      title: string;
      assignedGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      assignedMembers: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
      checklist: Array<{
        __typename?: 'ChecklistItem';
        checked: boolean;
        id: string;
        text: string;
      }>;
      creator: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      };
      dueDateOffset?: {
        __typename?: 'DateOffset';
        amount: number;
        unit: TimeUnit;
      } | null;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        color: string;
        text: string;
      }>;
      recurringRule?: {
        __typename?: 'RecurringRule';
        id: string;
        rule: string;
      } | null;
      reminders: Array<{
        __typename?: 'Reminder';
        amount: number;
        unit: TimeUnit;
      }>;
      topic: {
        __typename?: 'Topic';
        isPrivate: boolean;
        id: string;
        title: string;
        description?: string | null;
        groups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
          members: Array<{ __typename?: 'User'; id: string }>;
        }>;
        members: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          imageUrl?: string | null;
          status: UserStatus;
        }>;
      };
    };
  };
};

export type UserGroupInfoSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UserGroupInfoSubscription = {
  __typename?: 'Subscription';
  userGroupInfo: {
    __typename?: 'UserGroupInfo';
    action: ActionType;
    userGroup: {
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type UserNotificationsRemoveMutationVariables = Exact<{
  type: NotificationType;
  events: Array<NotificationEvent> | NotificationEvent;
}>;

export type UserNotificationsRemoveMutation = {
  __typename?: 'Mutation';
  userNotificationsRemove: {
    __typename?: 'UserNotifications';
    type: NotificationType;
    available: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    default: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    selected: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
  };
};

export type TopicInfoSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TopicInfoSubscription = {
  __typename?: 'Subscription';
  topicInfo: {
    __typename?: 'TopicInfo';
    action: ActionType;
    id: string;
    topic?: {
      __typename?: 'Topic';
      isPrivate: boolean;
      type?: TopicType | null;
      unreadMessages: number;
      id: string;
      title: string;
      description?: string | null;
      creator?: { __typename?: 'User'; id: string } | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    } | null;
  };
};

export type UserInfoSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UserInfoSubscription = {
  __typename?: 'Subscription';
  userInfo: {
    __typename?: 'UserInfo';
    user: { __typename?: 'User'; id: string; hasMessages: boolean };
  };
};

export type MessageInfoSubscriptionVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;

export type MessageInfoSubscription = {
  __typename?: 'Subscription';
  messageInfo: {
    __typename?: 'MessageInfo';
    action: ActionType;
    message: {
      __typename?: 'Message';
      readPercentage: number;
      createdAt: string;
      creatorType: MessageCreatorType;
      dueDate?: string | null;
      id: string;
      isArchived: boolean;
      isSeen: boolean;
      numberOfComments: number;
      status?: MessageStatus | null;
      text: string;
      title: string;
      type: MessageType;
      assignedGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      assignedMembers: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
      checklist: Array<{
        __typename?: 'ChecklistItem';
        id: string;
        checked: boolean;
        text: string;
      }>;
      readBy: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
      creator?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
        userGroups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
        }>;
      } | null;
      files: Array<{
        __typename?: 'File';
        id: string;
        key: string;
        extension: string;
        name: string;
        thumbnail?: string | null;
      }>;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        color: string;
        text: string;
      }>;
      reminders: Array<{
        __typename?: 'Reminder';
        amount: number;
        unit: TimeUnit;
      }>;
      thread?: {
        __typename?: 'Thread';
        id: string;
        isArchivedByGuest: boolean;
        isSupportingReplies: boolean;
        submissionLanguage: string;
        title: string;
      } | null;
      topic: {
        __typename?: 'Topic';
        id: string;
        isPrivate: boolean;
        groups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
          members: Array<{ __typename?: 'User'; id: string }>;
        }>;
        members: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          imageUrl?: string | null;
          status: UserStatus;
        }>;
      };
    };
  };
};

export type FileSignedDownloadUrlQueryVariables = Exact<{
  key: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
  isDownloadUrl?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FileSignedDownloadUrlQuery = {
  __typename?: 'Query';
  fileSignedDownloadUrlForMessage: string;
};

export type FileSignedUploadPostFieldsQueryVariables = Exact<{
  fileNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type FileSignedUploadPostFieldsQuery = {
  __typename?: 'Query';
  fileSignedUploadPostFields: Array<string>;
};

export type ReservationsQueryVariables = Exact<{ [key: string]: never }>;

export type ReservationsQuery = {
  __typename?: 'Query';
  reservations: Array<{
    __typename?: 'Reservation';
    id: number;
    resStatus: string;
    stayEnd: string;
    stayStart: string;
    uniqueId: string;
    connectedGuids: Array<{ __typename?: 'ConnectedGuid'; id: string }>;
    guests: Array<{
      __typename?: 'Guest';
      givenName?: string | null;
      id: number;
      surname?: string | null;
    }>;
  }>;
};

export type LabelsQueryVariables = Exact<{ [key: string]: never }>;

export type LabelsQuery = {
  __typename?: 'Query';
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
};

export type UserGroupBasicFragment = {
  __typename?: 'UserGroup';
  id: string;
  title: string;
};

export type AssigneesFullQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
  topicId: Scalars['ID']['input'];
}>;

export type AssigneesFullQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    id: string;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  } | null;
  topicAssignableGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
  }>;
  topicAssignableMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
};

export type UserGroupBasicWithMembersFragment = {
  __typename?: 'UserGroup';
  id: string;
  title: string;
  members: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
};

export type MessageFeedFragment = {
  __typename?: 'Message';
  createdAt: string;
  creatorType: MessageCreatorType;
  dueDate?: string | null;
  id: string;
  isArchived: boolean;
  isSeen: boolean;
  numberOfComments: number;
  status?: MessageStatus | null;
  text: string;
  title: string;
  type: MessageType;
  checklist: Array<{
    __typename?: 'ChecklistItem';
    id: string;
    checked: boolean;
  }>;
  assignedGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{ __typename?: 'User'; id: string }>;
  }>;
  assignedMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
  } | null;
  files: Array<{
    __typename?: 'File';
    id: string;
    key: string;
    extension: string;
    name: string;
    thumbnail?: string | null;
  }>;
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
  reminders: Array<{ __typename?: 'Reminder'; amount: number; unit: TimeUnit }>;
  thread?: {
    __typename?: 'Thread';
    id: string;
    isArchivedByGuest: boolean;
    isSupportingReplies: boolean;
    submissionLanguage: string;
    title: string;
  } | null;
  topic: {
    __typename?: 'Topic';
    id: string;
    isPrivate: boolean;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type MessageCommonFragment = {
  __typename?: 'Message';
  createdAt: string;
  creatorType: MessageCreatorType;
  dueDate?: string | null;
  id: string;
  isArchived: boolean;
  isSeen: boolean;
  numberOfComments: number;
  status?: MessageStatus | null;
  text: string;
  title: string;
  type: MessageType;
  assignedGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{ __typename?: 'User'; id: string }>;
  }>;
  assignedMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
  } | null;
  files: Array<{
    __typename?: 'File';
    id: string;
    key: string;
    extension: string;
    name: string;
    thumbnail?: string | null;
  }>;
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
  reminders: Array<{ __typename?: 'Reminder'; amount: number; unit: TimeUnit }>;
  thread?: {
    __typename?: 'Thread';
    id: string;
    isArchivedByGuest: boolean;
    isSupportingReplies: boolean;
    submissionLanguage: string;
    title: string;
  } | null;
  topic: {
    __typename?: 'Topic';
    id: string;
    isPrivate: boolean;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type ReservationStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ReservationStatusQuery = {
  __typename?: 'Query';
  reservationStatus: {
    __typename?: 'ReservationStatus';
    connected: boolean;
    id: string;
  };
};

export type TemplateBasicFragment = {
  __typename?: 'Template';
  id: string;
  title: string;
  creator: { __typename?: 'User'; id: string };
  recurringRule?: {
    __typename?: 'RecurringRule';
    id: string;
    rule: string;
  } | null;
  topic: {
    __typename?: 'Topic';
    isPrivate: boolean;
    id: string;
    title: string;
    description?: string | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type TemplateFullFragment = {
  __typename?: 'Template';
  id: string;
  text: string;
  title: string;
  assignedGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{ __typename?: 'User'; id: string }>;
  }>;
  assignedMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  checklist: Array<{
    __typename?: 'ChecklistItem';
    checked: boolean;
    id: string;
    text: string;
  }>;
  creator: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  };
  dueDateOffset?: {
    __typename?: 'DateOffset';
    amount: number;
    unit: TimeUnit;
  } | null;
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
  recurringRule?: {
    __typename?: 'RecurringRule';
    id: string;
    rule: string;
  } | null;
  reminders: Array<{ __typename?: 'Reminder'; amount: number; unit: TimeUnit }>;
  topic: {
    __typename?: 'Topic';
    isPrivate: boolean;
    id: string;
    title: string;
    description?: string | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type MessageFullFragment = {
  __typename?: 'Message';
  isArchived: boolean;
  readPercentage: number;
  createdAt: string;
  creatorType: MessageCreatorType;
  dueDate?: string | null;
  id: string;
  isSeen: boolean;
  numberOfComments: number;
  status?: MessageStatus | null;
  text: string;
  title: string;
  type: MessageType;
  checklist: Array<{
    __typename?: 'ChecklistItem';
    checked: boolean;
    id: string;
    text: string;
  }>;
  files: Array<{
    __typename?: 'File';
    id: string;
    key: string;
    extension: string;
    name: string;
    thumbnail?: string | null;
  }>;
  reminders: Array<{ __typename?: 'Reminder'; amount: number; unit: TimeUnit }>;
  readBy: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  assignedGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{ __typename?: 'User'; id: string }>;
  }>;
  assignedMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  creator?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
  } | null;
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
  thread?: {
    __typename?: 'Thread';
    id: string;
    isArchivedByGuest: boolean;
    isSupportingReplies: boolean;
    submissionLanguage: string;
    title: string;
  } | null;
  topic: {
    __typename?: 'Topic';
    id: string;
    isPrivate: boolean;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type UserNotificationsFragment = {
  __typename?: 'UserNotifications';
  type: NotificationType;
  available: {
    __typename?: 'GroupedNotifications';
    TOPICS: Array<NotificationEvent>;
    USERS: Array<NotificationEvent>;
    USER_GROUPS: Array<NotificationEvent>;
    LABELS: Array<NotificationEvent>;
    MESSAGE_IS_CREATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
    MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
    MESSAGE_IS_DELETED: Array<NotificationEvent>;
    REMINDERS: Array<NotificationEvent>;
    COMMENTS: Array<NotificationEvent>;
    CHAT: Array<NotificationEvent>;
  };
  default: {
    __typename?: 'GroupedNotifications';
    TOPICS: Array<NotificationEvent>;
    USERS: Array<NotificationEvent>;
    USER_GROUPS: Array<NotificationEvent>;
    LABELS: Array<NotificationEvent>;
    MESSAGE_IS_CREATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
    MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
    MESSAGE_IS_DELETED: Array<NotificationEvent>;
    REMINDERS: Array<NotificationEvent>;
    COMMENTS: Array<NotificationEvent>;
    CHAT: Array<NotificationEvent>;
  };
  selected: {
    __typename?: 'GroupedNotifications';
    TOPICS: Array<NotificationEvent>;
    USERS: Array<NotificationEvent>;
    USER_GROUPS: Array<NotificationEvent>;
    LABELS: Array<NotificationEvent>;
    MESSAGE_IS_CREATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
    MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
    MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
    MESSAGE_IS_DELETED: Array<NotificationEvent>;
    REMINDERS: Array<NotificationEvent>;
    COMMENTS: Array<NotificationEvent>;
    CHAT: Array<NotificationEvent>;
  };
};

export type TopicBasicFragment = {
  __typename?: 'Topic';
  id: string;
  isPrivate: boolean;
  title: string;
  description?: string | null;
};

export type AssigneesQueryVariables = Exact<{ [key: string]: never }>;

export type AssigneesQuery = {
  __typename?: 'Query';
  userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
  users: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
};

export type ChecklistItemCreateMutationVariables = Exact<{
  messageId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  isChecked: Scalars['Boolean']['input'];
  position: Scalars['Int']['input'];
}>;

export type ChecklistItemCreateMutation = {
  __typename?: 'Mutation';
  checklistCreateItem: {
    __typename?: 'Message';
    id: string;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
  };
};

export type UserSettingsFragment = {
  __typename?: 'User';
  email: string;
  targetLanguage?: TargetLanguage | null;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  status: UserStatus;
};

export type TopicWithMembersFragment = {
  __typename?: 'Topic';
  isPrivate: boolean;
  type?: TopicType | null;
  unreadMessages: number;
  id: string;
  title: string;
  description?: string | null;
  creator?: { __typename?: 'User'; id: string } | null;
  groups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{ __typename?: 'User'; id: string }>;
  }>;
  members: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
};

export type DashboardKpiQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardKpiQuery = {
  __typename?: 'Query';
  dashboardKpi: Array<{
    __typename?: 'DashboardKpi';
    searchInfo: { __typename?: 'SearchInfo'; total: number };
    searchQuery: {
      __typename?: 'SearchQuery';
      dashboardKpiType?: DashboardKpiType | null;
      id: string;
      title: {
        __typename?: 'SearchQueryTitle';
        de: string;
        en: string;
        fr: string;
        it: string;
        nl: string;
      };
    };
  }>;
};

export type ChecklistItemSetStateMutationVariables = Exact<{
  messageId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  state: Scalars['Boolean']['input'];
}>;

export type ChecklistItemSetStateMutation = {
  __typename?: 'Mutation';
  checklistCheckItem: {
    __typename?: 'Message';
    id: string;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
  };
};

export type ChecklistItemDeleteMutationVariables = Exact<{
  itemId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
}>;

export type ChecklistItemDeleteMutation = {
  __typename?: 'Mutation';
  checklistDeleteItem: {
    __typename?: 'Message';
    id: string;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
  };
};

export type UserMeFragment = {
  __typename?: 'User';
  createdAt: string;
  email: string;
  hasMessages: boolean;
  language: Locale;
  selectedHotel?: string | null;
  targetLanguage?: TargetLanguage | null;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  status: UserStatus;
  userGroups: Array<{ __typename?: 'UserGroup'; id: string }>;
  hotelPermissions: Array<{
    __typename?: 'HotelPermissions';
    permissions: Array<string>;
    roles: Array<string>;
    hotel: {
      __typename?: 'Hotel';
      id: string;
      isChatEnabled: boolean;
      uuid: string;
      name: string;
      imageUrl?: string | null;
      isTesting: boolean;
      status: HotelStatus;
    };
  }>;
};

export type ChecklistItemReorderMutationVariables = Exact<{
  messageId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
}>;

export type ChecklistItemReorderMutation = {
  __typename?: 'Mutation';
  checklistReorder: {
    __typename?: 'Message';
    id: string;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
  };
};

export type FilesDeleteFromMessageMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
}>;

export type FilesDeleteFromMessageMutation = {
  __typename?: 'Mutation';
  filesDeleteFromMessage: {
    __typename?: 'Message';
    id: string;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
  };
};

export type UserBasicFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  status: UserStatus;
};

export type UserGroupDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserGroupDeleteMutation = {
  __typename?: 'Mutation';
  userGroupDelete: { __typename?: 'ActionResult'; status: boolean };
};

export type ChecklistItemSetTextMutationVariables = Exact<{
  itemId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
}>;

export type ChecklistItemSetTextMutation = {
  __typename?: 'Mutation';
  checklistUpdateItem: {
    __typename?: 'Message';
    id: string;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
  };
};

export type LabelDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LabelDeleteMutation = {
  __typename?: 'Mutation';
  labelDelete: { __typename?: 'ActionResult'; status: boolean };
};

export type GroupCreateMutationVariables = Exact<{
  data: UserGroupCreateInput;
}>;

export type GroupCreateMutation = {
  __typename?: 'Mutation';
  userGroupCreate: { __typename?: 'UserGroup'; id: string; title: string };
};

export type GroupUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: UserGroupUpdateInput;
}>;

export type GroupUpdateMutation = {
  __typename?: 'Mutation';
  userGroupUpdate: { __typename?: 'UserGroup'; id: string; title: string };
};

export type FilesAddToMessageMutationVariables = Exact<{
  keys: Array<Scalars['String']['input']> | Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
}>;

export type FilesAddToMessageMutation = {
  __typename?: 'Mutation';
  filesAddToMessage: {
    __typename?: 'Message';
    id: string;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
  };
};

export type LabelCreateMutationVariables = Exact<{
  text: Scalars['String']['input'];
  color: Scalars['Color']['input'];
}>;

export type LabelCreateMutation = {
  __typename?: 'Mutation';
  labelCreate: {
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  };
};

export type MessageArchiveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MessageArchiveMutation = {
  __typename?: 'Mutation';
  messageArchive: { __typename?: 'Message'; id: string };
};

export type MessageUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: MessageUpdateInput;
}>;

export type MessageUpdateMutation = {
  __typename?: 'Mutation';
  messageUpdate: {
    __typename?: 'Message';
    createdAt: string;
    creatorType: MessageCreatorType;
    dueDate?: string | null;
    id: string;
    isArchived: boolean;
    isSeen: boolean;
    numberOfComments: number;
    status?: MessageStatus | null;
    text: string;
    title: string;
    type: MessageType;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
      userGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
      }>;
    } | null;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    thread?: {
      __typename?: 'Thread';
      id: string;
      isArchivedByGuest: boolean;
      isSupportingReplies: boolean;
      submissionLanguage: string;
      title: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      id: string;
      isPrivate: boolean;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type MessageUnArchiveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MessageUnArchiveMutation = {
  __typename?: 'Mutation';
  messageUnarchive: {
    __typename?: 'Message';
    isArchived: boolean;
    readPercentage: number;
    createdAt: string;
    creatorType: MessageCreatorType;
    dueDate?: string | null;
    id: string;
    isSeen: boolean;
    numberOfComments: number;
    status?: MessageStatus | null;
    text: string;
    title: string;
    type: MessageType;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    readBy: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
      userGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
      }>;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    thread?: {
      __typename?: 'Thread';
      id: string;
      isArchivedByGuest: boolean;
      isSupportingReplies: boolean;
      submissionLanguage: string;
      title: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      id: string;
      isPrivate: boolean;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type MessageDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MessageDeleteMutation = {
  __typename?: 'Mutation';
  messageDelete: { __typename?: 'ActionResult'; status: boolean };
};

export type MessageCreateMutationVariables = Exact<{
  data: MessageInput;
}>;

export type MessageCreateMutation = {
  __typename?: 'Mutation';
  messageCreate: {
    __typename?: 'Message';
    isArchived: boolean;
    readPercentage: number;
    createdAt: string;
    creatorType: MessageCreatorType;
    dueDate?: string | null;
    id: string;
    isSeen: boolean;
    numberOfComments: number;
    status?: MessageStatus | null;
    text: string;
    title: string;
    type: MessageType;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    readBy: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
      userGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
      }>;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    thread?: {
      __typename?: 'Thread';
      id: string;
      isArchivedByGuest: boolean;
      isSupportingReplies: boolean;
      submissionLanguage: string;
      title: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      id: string;
      isPrivate: boolean;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type MessageStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: MessageStatus;
}>;

export type MessageStatusUpdateMutation = {
  __typename?: 'Mutation';
  messageStatusUpdate: {
    __typename?: 'Message';
    id: string;
    status?: MessageStatus | null;
  };
};

export type PushSubscribeMutationVariables = Exact<{
  token: Scalars['String']['input'];
  deviceId: Scalars['ID']['input'];
  deviceName: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
}>;

export type PushSubscribeMutation = {
  __typename?: 'Mutation';
  pushNotificationSubscribe: { __typename?: 'PushNotification'; id: string };
};

export type MessagesReadMutationVariables = Exact<{
  messageIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type MessagesReadMutation = {
  __typename?: 'Mutation';
  messagesRead: { __typename?: 'ActionResult'; status: boolean };
};

export type LabelUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  color: Scalars['Color']['input'];
}>;

export type LabelUpdateMutation = {
  __typename?: 'Mutation';
  labelUpdate: {
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  };
};

export type RoqTokenCreateMutationVariables = Exact<{ [key: string]: never }>;

export type RoqTokenCreateMutation = {
  __typename?: 'Mutation';
  roqTokenCreate: { __typename?: 'RoqToken'; token: string };
};

export type RecurringRuleSaveMutationVariables = Exact<{
  templateId: Scalars['ID']['input'];
  data: RecurringRuleInput;
}>;

export type RecurringRuleSaveMutation = {
  __typename?: 'Mutation';
  recurringRuleSave: {
    __typename?: 'Template';
    id: string;
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
  };
};

export type PushUnsubscribeMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type PushUnsubscribeMutation = {
  __typename?: 'Mutation';
  pushNotificationUnsubscribe: { __typename?: 'ActionResult'; status: boolean };
};

export type TopicCreateMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
  members: Array<Scalars['String']['input']> | Scalars['String']['input'];
  groups: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type TopicCreateMutation = {
  __typename?: 'Mutation';
  topicCreate: {
    __typename?: 'Topic';
    isPrivate: boolean;
    type?: TopicType | null;
    unreadMessages: number;
    id: string;
    title: string;
    description?: string | null;
    creator?: { __typename?: 'User'; id: string } | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type RecurringRuleDeleteMutationVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;

export type RecurringRuleDeleteMutation = {
  __typename?: 'Mutation';
  recurringRuleDelete: { __typename?: 'Template'; id: string };
};

export type TemplateUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: TemplateUpdateInput;
}>;

export type TemplateUpdateMutation = {
  __typename?: 'Mutation';
  templateUpdate: {
    __typename?: 'Template';
    id: string;
    text: string;
    title: string;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    };
    dueDateOffset?: {
      __typename?: 'DateOffset';
      amount: number;
      unit: TimeUnit;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    topic: {
      __typename?: 'Topic';
      isPrivate: boolean;
      id: string;
      title: string;
      description?: string | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type TemplateCreateMutationVariables = Exact<{
  data: TemplateInput;
}>;

export type TemplateCreateMutation = {
  __typename?: 'Mutation';
  templateCreate: {
    __typename?: 'Template';
    id: string;
    text: string;
    title: string;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    };
    dueDateOffset?: {
      __typename?: 'DateOffset';
      amount: number;
      unit: TimeUnit;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    topic: {
      __typename?: 'Topic';
      isPrivate: boolean;
      id: string;
      title: string;
      description?: string | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type TopicUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: TopicUpdateInput;
}>;

export type TopicUpdateMutation = {
  __typename?: 'Mutation';
  topicUpdate: {
    __typename?: 'Topic';
    isPrivate: boolean;
    type?: TopicType | null;
    unreadMessages: number;
    id: string;
    title: string;
    description?: string | null;
    creator?: { __typename?: 'User'; id: string } | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type TopicDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicDeleteMutation = {
  __typename?: 'Mutation';
  topicDelete: { __typename?: 'ActionResult'; status: boolean };
};

export type UserGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type UserGroupsQuery = {
  __typename?: 'Query';
  userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
};

export type UserSelectHotelMutationVariables = Exact<{ [key: string]: never }>;

export type UserSelectHotelMutation = {
  __typename?: 'Mutation';
  userSelectHotel: {
    __typename?: 'User';
    selectedHotel?: string | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      roles: Array<string>;
    }>;
  };
};

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: UserUpdateInput;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    targetLanguage?: TargetLanguage | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      roles: Array<string>;
    }>;
  };
};

export type NotificationsLocalizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationsLocalizationQuery = {
  __typename?: 'Query';
  localization: {
    __typename?: 'I18n';
    notifications: Array<{
      __typename?: 'I18nLocalization';
      label: string;
      value: string;
    }>;
  };
};

export type UserDeactivateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserDeactivateMutation = {
  __typename?: 'Mutation';
  userDeactivate: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      roles: Array<string>;
    }>;
  };
};

export type TemplateDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemplateDeleteMutation = {
  __typename?: 'Mutation';
  templateDelete: { __typename?: 'ActionResult'; status: boolean };
};

export type UserActivateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserActivateMutation = {
  __typename?: 'Mutation';
  userActivate: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      roles: Array<string>;
    }>;
  };
};

export type UserLocalizationQueryVariables = Exact<{ [key: string]: never }>;

export type UserLocalizationQuery = {
  __typename?: 'Query';
  localization: {
    __typename?: 'I18n';
    roles: Array<{
      __typename?: 'I18nRole';
      description: string;
      title: string;
      value: string;
    }>;
    targetLanguages: Array<{
      __typename?: 'I18nTargetLanguage';
      label: string;
      value?: string | null;
    }>;
  };
};

export type UserGroupsWithMembersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserGroupsWithMembersQuery = {
  __typename?: 'Query';
  userGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  }>;
};

export type PushInfoQueryVariables = Exact<{ [key: string]: never }>;

export type PushInfoQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    createdAt: string;
    email: string;
    hasMessages: boolean;
    language: Locale;
    selectedHotel?: string | null;
    targetLanguage?: TargetLanguage | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    userGroups: Array<{ __typename?: 'UserGroup'; id: string }>;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      permissions: Array<string>;
      roles: Array<string>;
      hotel: {
        __typename?: 'Hotel';
        id: string;
        isChatEnabled: boolean;
        uuid: string;
        name: string;
        imageUrl?: string | null;
        isTesting: boolean;
        status: HotelStatus;
      };
    }>;
  };
  pushSubscriptions: Array<{
    __typename?: 'PushNotification';
    id: string;
    token: string;
    device: { __typename?: 'Device'; id: string; name: string; type: string };
  }>;
};

export type MessageLabelsQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageLabelsQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    id: string;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
  } | null;
  labels: Array<{
    __typename?: 'Label';
    id: string;
    color: string;
    text: string;
  }>;
};

export type MessageFullQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageFullQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    isArchived: boolean;
    readPercentage: number;
    createdAt: string;
    creatorType: MessageCreatorType;
    dueDate?: string | null;
    id: string;
    isSeen: boolean;
    numberOfComments: number;
    status?: MessageStatus | null;
    text: string;
    title: string;
    type: MessageType;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    readBy: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
      userGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
      }>;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    thread?: {
      __typename?: 'Thread';
      id: string;
      isArchivedByGuest: boolean;
      isSupportingReplies: boolean;
      submissionLanguage: string;
      title: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      id: string;
      isPrivate: boolean;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  } | null;
};

export type MessageTextQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageTextQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    id: string;
    text: string;
    title: string;
  } | null;
};

export type MessageDateQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageDateQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    id: string;
    dueDate?: string | null;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
  } | null;
};

export type PushSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type PushSubscriptionsQuery = {
  __typename?: 'Query';
  pushSubscriptions: Array<{
    __typename?: 'PushNotification';
    id: string;
    token: string;
    device: { __typename?: 'Device'; id: string; name: string; type: string };
  }>;
};

export type CommentsQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
  first: Scalars['Int']['input'];
  after: Scalars['ID']['input'];
  includeTranslations?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CommentsQuery = {
  __typename?: 'Query';
  comments: {
    __typename?: 'PaginationResult';
    nodes: Array<
      | { __typename?: 'Message' }
      | {
          __typename?: 'Comment';
          id: string;
          createdAt: string;
          isForExternal: boolean;
          text: string;
          creator?: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            imageUrl?: string | null;
            status: UserStatus;
          } | null;
          translation?: { __typename?: 'CommentTranslation'; text: string };
          message: { __typename?: 'Message'; id: string };
        }
    >;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
      startCursor: string;
      hasPreviousPage: boolean;
    };
  };
};

export type MessageRightSidebarQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageRightSidebarQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    createdAt: string;
    creatorType: MessageCreatorType;
    dueDate?: string | null;
    id: string;
    isArchived: boolean;
    isSeen: boolean;
    numberOfComments: number;
    status?: MessageStatus | null;
    text: string;
    title: string;
    type: MessageType;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    creator?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
      userGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
      }>;
    } | null;
    files: Array<{
      __typename?: 'File';
      id: string;
      key: string;
      extension: string;
      name: string;
      thumbnail?: string | null;
    }>;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    thread?: {
      __typename?: 'Thread';
      id: string;
      isArchivedByGuest: boolean;
      isSupportingReplies: boolean;
      submissionLanguage: string;
      title: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      id: string;
      isPrivate: boolean;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  } | null;
};

export type MessagesQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
}>;

export type MessagesQuery = {
  __typename?: 'Query';
  messages: {
    __typename?: 'PaginationResult';
    nodes: Array<
      | {
          __typename?: 'Message';
          createdAt: string;
          creatorType: MessageCreatorType;
          dueDate?: string | null;
          id: string;
          isArchived: boolean;
          isSeen: boolean;
          numberOfComments: number;
          status?: MessageStatus | null;
          text: string;
          title: string;
          type: MessageType;
          checklist: Array<{
            __typename?: 'ChecklistItem';
            id: string;
            checked: boolean;
          }>;
          assignedGroups: Array<{
            __typename?: 'UserGroup';
            id: string;
            title: string;
            members: Array<{ __typename?: 'User'; id: string }>;
          }>;
          assignedMembers: Array<{
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            imageUrl?: string | null;
            status: UserStatus;
          }>;
          creator?: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            imageUrl?: string | null;
            status: UserStatus;
            userGroups: Array<{
              __typename?: 'UserGroup';
              id: string;
              title: string;
            }>;
          } | null;
          files: Array<{
            __typename?: 'File';
            id: string;
            key: string;
            extension: string;
            name: string;
            thumbnail?: string | null;
          }>;
          labels: Array<{
            __typename?: 'Label';
            id: string;
            color: string;
            text: string;
          }>;
          reminders: Array<{
            __typename?: 'Reminder';
            amount: number;
            unit: TimeUnit;
          }>;
          thread?: {
            __typename?: 'Thread';
            id: string;
            isArchivedByGuest: boolean;
            isSupportingReplies: boolean;
            submissionLanguage: string;
            title: string;
          } | null;
          topic: {
            __typename?: 'Topic';
            id: string;
            isPrivate: boolean;
            groups: Array<{
              __typename?: 'UserGroup';
              id: string;
              title: string;
              members: Array<{ __typename?: 'User'; id: string }>;
            }>;
            members: Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              imageUrl?: string | null;
              status: UserStatus;
            }>;
          };
        }
      | { __typename?: 'Comment' }
    >;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor: string;
      startCursor: string;
      hasPreviousPage: boolean;
    };
  };
};

export type MessageRankedSearchQueryVariables = Exact<{
  params: SearchParams;
}>;

export type MessageRankedSearchQuery = {
  __typename?: 'Query';
  messageRankedSearch: {
    __typename?: 'SearchResult';
    nodes: Array<{
      __typename?: 'Message';
      createdAt: string;
      creatorType: MessageCreatorType;
      dueDate?: string | null;
      id: string;
      isArchived: boolean;
      isSeen: boolean;
      numberOfComments: number;
      status?: MessageStatus | null;
      text: string;
      title: string;
      type: MessageType;
      topic: {
        __typename?: 'Topic';
        id: string;
        isPrivate: boolean;
        groups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
          members: Array<{ __typename?: 'User'; id: string }>;
        }>;
        members: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          imageUrl?: string | null;
          status: UserStatus;
        }>;
      };
      checklist: Array<{
        __typename?: 'ChecklistItem';
        id: string;
        checked: boolean;
      }>;
      assignedGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      assignedMembers: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
      creator?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
        userGroups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
        }>;
      } | null;
      files: Array<{
        __typename?: 'File';
        id: string;
        key: string;
        extension: string;
        name: string;
        thumbnail?: string | null;
      }>;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        color: string;
        text: string;
      }>;
      reminders: Array<{
        __typename?: 'Reminder';
        amount: number;
        unit: TimeUnit;
      }>;
      thread?: {
        __typename?: 'Thread';
        id: string;
        isArchivedByGuest: boolean;
        isSupportingReplies: boolean;
        submissionLanguage: string;
        title: string;
      } | null;
    }>;
    searchInfo: { __typename?: 'SearchInfo'; total: number };
  };
};

export type MessageIsArchivedQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageIsArchivedQuery = {
  __typename?: 'Query';
  message?: { __typename?: 'Message'; id: string; isArchived: boolean } | null;
};

export type MessageTranslationQueryVariables = Exact<{
  messageId: Scalars['ID']['input'];
}>;

export type MessageTranslationQuery = {
  __typename?: 'Query';
  message?: {
    __typename?: 'Message';
    creatorType: MessageCreatorType;
    id: string;
    title: string;
    type: MessageType;
    translation: {
      __typename?: 'MessageTranslation';
      title: string;
      text: string;
    };
    checklist: Array<{
      __typename?: 'ChecklistItem';
      id: string;
      translation: { __typename?: 'ChecklistItemTranslation'; text: string };
    }>;
  } | null;
};

export type SearchQueriesQueryVariables = Exact<{
  type?: InputMaybe<SearchQueryType>;
}>;

export type SearchQueriesQuery = {
  __typename?: 'Query';
  searchQueries: Array<{
    __typename?: 'SearchQuery';
    id: string;
    title: {
      __typename?: 'SearchQueryTitle';
      de: string;
      en: string;
      fr: string;
      it: string;
      nl: string;
    };
  }>;
};

export type MessageSearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
}>;

export type MessageSearchQuery = {
  __typename?: 'Query';
  messageSearch: {
    __typename?: 'SearchResult';
    nodes: Array<{
      __typename?: 'Message';
      createdAt: string;
      creatorType: MessageCreatorType;
      dueDate?: string | null;
      id: string;
      isArchived: boolean;
      isSeen: boolean;
      numberOfComments: number;
      status?: MessageStatus | null;
      text: string;
      title: string;
      type: MessageType;
      topic: {
        __typename?: 'Topic';
        id: string;
        isPrivate: boolean;
        groups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
          members: Array<{ __typename?: 'User'; id: string }>;
        }>;
        members: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          imageUrl?: string | null;
          status: UserStatus;
        }>;
      };
      checklist: Array<{
        __typename?: 'ChecklistItem';
        id: string;
        checked: boolean;
      }>;
      assignedGroups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      assignedMembers: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
      creator?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
        userGroups: Array<{
          __typename?: 'UserGroup';
          id: string;
          title: string;
        }>;
      } | null;
      files: Array<{
        __typename?: 'File';
        id: string;
        key: string;
        extension: string;
        name: string;
        thumbnail?: string | null;
      }>;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        color: string;
        text: string;
      }>;
      reminders: Array<{
        __typename?: 'Reminder';
        amount: number;
        unit: TimeUnit;
      }>;
      thread?: {
        __typename?: 'Thread';
        id: string;
        isArchivedByGuest: boolean;
        isSupportingReplies: boolean;
        submissionLanguage: string;
        title: string;
      } | null;
    }>;
    searchInfo: { __typename?: 'SearchInfo'; total: number };
  };
};

export type TasksQueryVariables = Exact<{
  filter: TasksFilterInput;
  pagination: PaginationInput;
}>;

export type TasksQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'PaginationResultExtended';
    nodes: Array<
      | {
          __typename?: 'Message';
          createdAt: string;
          creatorType: MessageCreatorType;
          dueDate?: string | null;
          id: string;
          isArchived: boolean;
          isSeen: boolean;
          numberOfComments: number;
          status?: MessageStatus | null;
          text: string;
          title: string;
          type: MessageType;
          checklist: Array<{
            __typename?: 'ChecklistItem';
            id: string;
            checked: boolean;
          }>;
          assignedGroups: Array<{
            __typename?: 'UserGroup';
            id: string;
            title: string;
            members: Array<{ __typename?: 'User'; id: string }>;
          }>;
          assignedMembers: Array<{
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            imageUrl?: string | null;
            status: UserStatus;
          }>;
          creator?: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            imageUrl?: string | null;
            status: UserStatus;
            userGroups: Array<{
              __typename?: 'UserGroup';
              id: string;
              title: string;
            }>;
          } | null;
          files: Array<{
            __typename?: 'File';
            id: string;
            key: string;
            extension: string;
            name: string;
            thumbnail?: string | null;
          }>;
          labels: Array<{
            __typename?: 'Label';
            id: string;
            color: string;
            text: string;
          }>;
          reminders: Array<{
            __typename?: 'Reminder';
            amount: number;
            unit: TimeUnit;
          }>;
          thread?: {
            __typename?: 'Thread';
            id: string;
            isArchivedByGuest: boolean;
            isSupportingReplies: boolean;
            submissionLanguage: string;
            title: string;
          } | null;
          topic: {
            __typename?: 'Topic';
            id: string;
            isPrivate: boolean;
            groups: Array<{
              __typename?: 'UserGroup';
              id: string;
              title: string;
              members: Array<{ __typename?: 'User'; id: string }>;
            }>;
            members: Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              imageUrl?: string | null;
              status: UserStatus;
            }>;
          };
        }
      | { __typename?: 'Comment' }
    >;
    nodesInfo: { __typename?: 'NodesInfo'; returned: number; total: number };
  };
};

export type TemplateQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
}>;

export type TemplateQuery = {
  __typename?: 'Query';
  template: {
    __typename?: 'Template';
    id: string;
    text: string;
    title: string;
    assignedGroups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    assignedMembers: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
    checklist: Array<{
      __typename?: 'ChecklistItem';
      checked: boolean;
      id: string;
      text: string;
    }>;
    creator: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    };
    dueDateOffset?: {
      __typename?: 'DateOffset';
      amount: number;
      unit: TimeUnit;
    } | null;
    labels: Array<{
      __typename?: 'Label';
      id: string;
      color: string;
      text: string;
    }>;
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
    reminders: Array<{
      __typename?: 'Reminder';
      amount: number;
      unit: TimeUnit;
    }>;
    topic: {
      __typename?: 'Topic';
      isPrivate: boolean;
      id: string;
      title: string;
      description?: string | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  };
};

export type TemplatesInTopicQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;

export type TemplatesInTopicQuery = {
  __typename?: 'Query';
  templatesInTopic: Array<{
    __typename?: 'Template';
    id: string;
    title: string;
    creator: { __typename?: 'User'; id: string };
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      isPrivate: boolean;
      id: string;
      title: string;
      description?: string | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  }>;
};

export type TopicBasicQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicBasicQuery = {
  __typename?: 'Query';
  topic: {
    __typename?: 'Topic';
    id: string;
    isPrivate: boolean;
    title: string;
    description?: string | null;
  };
};

export type SearchQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  context?: InputMaybe<SearchQueryContext>;
}>;

export type SearchQueryQuery = {
  __typename?: 'Query';
  searchQuery: {
    __typename?: 'SearchQuery';
    query: string;
    sort?: string | null;
    id: string;
    title: {
      __typename?: 'SearchQueryTitle';
      de: string;
      en: string;
      fr: string;
      it: string;
      nl: string;
    };
  };
};

export type TopicSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type TopicSettingsQuery = {
  __typename?: 'Query';
  topicsAll: Array<{
    __typename?: 'Topic';
    isPrivate: boolean;
    type?: TopicType | null;
    unreadMessages: number;
    id: string;
    title: string;
    description?: string | null;
    creator?: { __typename?: 'User'; id: string } | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  }>;
};

export type TopicAssignableQueryVariables = Exact<{
  topicId: Scalars['ID']['input'];
}>;

export type TopicAssignableQuery = {
  __typename?: 'Query';
  topicAssignableGroups: Array<{
    __typename?: 'UserGroup';
    id: string;
    title: string;
  }>;
  topicAssignableMembers: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
};

export type TopicDescriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicDescriptionQuery = {
  __typename?: 'Query';
  topic: { __typename?: 'Topic'; id: string; description?: string | null };
};

export type TopicTitleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicTitleQuery = {
  __typename?: 'Query';
  topic: { __typename?: 'Topic'; id: string; title: string };
};

export type TopicsQueryVariables = Exact<{ [key: string]: never }>;

export type TopicsQuery = {
  __typename?: 'Query';
  topics: Array<{ __typename?: 'Topic'; id: string; title: string }>;
};

export type TopicsListQueryVariables = Exact<{ [key: string]: never }>;

export type TopicsListQuery = {
  __typename?: 'Query';
  topics: Array<{
    __typename?: 'Topic';
    id: string;
    title: string;
    type?: TopicType | null;
    unreadMessages: number;
  }>;
};

export type TopicWithMembersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicWithMembersQuery = {
  __typename?: 'Query';
  topic: {
    __typename?: 'Topic';
    isPrivate: boolean;
    type?: TopicType | null;
    unreadMessages: number;
    id: string;
    title: string;
    description?: string | null;
    creator?: { __typename?: 'User'; id: string } | null;
    groups: Array<{
      __typename?: 'UserGroup';
      id: string;
      title: string;
      members: Array<{ __typename?: 'User'; id: string }>;
    }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
};

export type LocaleQueryVariables = Exact<{ [key: string]: never }>;

export type LocaleQuery = {
  __typename?: 'Query';
  localization: {
    __typename?: 'I18n';
    translations: Array<{
      __typename?: 'I18nLocalization';
      label: string;
      value: string;
    }>;
  };
  me: {
    __typename?: 'User';
    id: string;
    language: Locale;
    localization: string;
  };
};

export type TopicsUnreadQueryVariables = Exact<{ [key: string]: never }>;

export type TopicsUnreadQuery = {
  __typename?: 'Query';
  topics: Array<{ __typename?: 'Topic'; id: string; unreadMessages: number }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    createdAt: string;
    email: string;
    hasMessages: boolean;
    language: Locale;
    selectedHotel?: string | null;
    targetLanguage?: TargetLanguage | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    userGroups: Array<{ __typename?: 'UserGroup'; id: string }>;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      permissions: Array<string>;
      roles: Array<string>;
      hotel: {
        __typename?: 'Hotel';
        id: string;
        isChatEnabled: boolean;
        uuid: string;
        name: string;
        imageUrl?: string | null;
        isTesting: boolean;
        status: HotelStatus;
      };
    }>;
  };
};

export type UserNotificationsQueryVariables = Exact<{
  type: NotificationType;
}>;

export type UserNotificationsQuery = {
  __typename?: 'Query';
  userNotifications: {
    __typename?: 'UserNotifications';
    type: NotificationType;
    available: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    default: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
    selected: {
      __typename?: 'GroupedNotifications';
      TOPICS: Array<NotificationEvent>;
      USERS: Array<NotificationEvent>;
      USER_GROUPS: Array<NotificationEvent>;
      LABELS: Array<NotificationEvent>;
      MESSAGE_IS_CREATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_ASSIGNEES: Array<NotificationEvent>;
      MESSAGE_IS_UPDATED__FOR_CREATOR: Array<NotificationEvent>;
      MESSAGE_IS_ARCHIVED: Array<NotificationEvent>;
      MESSAGE_IS_DELETED: Array<NotificationEvent>;
      REMINDERS: Array<NotificationEvent>;
      COMMENTS: Array<NotificationEvent>;
      CHAT: Array<NotificationEvent>;
    };
  };
};

export type TopicAssigneesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TopicAssigneesQuery = {
  __typename?: 'Query';
  topic: {
    __typename?: 'Topic';
    id: string;
    isPrivate: boolean;
    groups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
    members: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      status: UserStatus;
    }>;
  };
  users: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
  }>;
  userGroups: Array<{ __typename?: 'UserGroup'; id: string; title: string }>;
};

export type UserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type UserSettingsQuery = {
  __typename?: 'Query';
  localization: {
    __typename?: 'I18n';
    roles: Array<{
      __typename?: 'I18nRole';
      description: string;
      title: string;
      value: string;
    }>;
  };
  usersAll: Array<{
    __typename?: 'User';
    email: string;
    targetLanguage?: TargetLanguage | null;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    status: UserStatus;
    hotelPermissions: Array<{
      __typename?: 'HotelPermissions';
      permissions: Array<string>;
      roles: Array<string>;
      hotel: {
        __typename?: 'Hotel';
        id: string;
        isChatEnabled: boolean;
        uuid: string;
        name: string;
        imageUrl?: string | null;
        isTesting: boolean;
        status: HotelStatus;
      };
    }>;
  }>;
};

export type TemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type TemplatesQuery = {
  __typename?: 'Query';
  templatesEditable: Array<{
    __typename?: 'Template';
    id: string;
    title: string;
    creator: { __typename?: 'User'; id: string };
    recurringRule?: {
      __typename?: 'RecurringRule';
      id: string;
      rule: string;
    } | null;
    topic: {
      __typename?: 'Topic';
      isPrivate: boolean;
      id: string;
      title: string;
      description?: string | null;
      groups: Array<{
        __typename?: 'UserGroup';
        id: string;
        title: string;
        members: Array<{ __typename?: 'User'; id: string }>;
      }>;
      members: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        status: UserStatus;
      }>;
    };
  }>;
};

export const UserBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBasicFragment, unknown>;
export const CommentFullFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommentFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'translation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeTranslations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isForExternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentFullFragment, unknown>;
export const GuestBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GuestBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Guest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GuestBasicFragment, unknown>;
export const ReservationBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReservationBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reservation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedGuids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'guid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'guests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GuestBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stayEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stayStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GuestBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Guest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReservationBasicFragment, unknown>;
export const SearchQueryBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchQueryBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchQuery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'title' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'de' } },
                { kind: 'Field', name: { kind: 'Name', value: 'en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'it' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQueryBasicFragment, unknown>;
export const UserGroupBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupBasicFragment, unknown>;
export const UserGroupBasicWithMembersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroupBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupBasicWithMembersFragment, unknown>;
export const AttachmentBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachmentBasicFragment, unknown>;
export const LabelBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelBasicFragment, unknown>;
export const ReminderItemBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReminderItemBasicFragment, unknown>;
export const MessageCommonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageCommonFragment, unknown>;
export const MessageFeedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFeedFragment, unknown>;
export const TopicBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicBasicFragment, unknown>;
export const TemplateBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateBasicFragment, unknown>;
export const ChecklistItemBasicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChecklistItemBasicFragment, unknown>;
export const TemplateFullFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dueDateOffset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateFullFragment, unknown>;
export const MessageFullFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'readPercentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFullFragment, unknown>;
export const GroupedNotificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupedNotificationsFragment, unknown>;
export const UserNotificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserNotificationsFragment, unknown>;
export const UserSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetLanguage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSettingsFragment, unknown>;
export const TopicWithMembersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicWithMembersFragment, unknown>;
export const HotelPermissionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HotelPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isChatEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTesting' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HotelPermissionsFragment, unknown>;
export const UserMeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMe' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMessages' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HotelPermissions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedHotel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HotelPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isChatEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTesting' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserMeFragment, unknown>;
export const CommentCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'commentCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isForExternal' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeTranslations' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'messageId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isForExternal' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isForExternal' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CommentFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommentFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'translation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeTranslations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isForExternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CommentCreateMutation,
  CommentCreateMutationVariables
>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFirstLogin' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'language' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hotelPermissions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hotel' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'permissions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const UserNotificationsAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserNotificationsAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'events' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'NotificationEvent' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotificationsAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'events' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'events' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserNotificationsAddMutation,
  UserNotificationsAddMutationVariables
>;
export const UserNotificationsUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserNotificationsUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'events' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'NotificationEvent' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotificationsUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'events' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'events' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserNotificationsUpdateMutation,
  UserNotificationsUpdateMutationVariables
>;
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const CommentInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'CommentInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeTranslations' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CommentFull' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommentFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'translation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeTranslations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isForExternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CommentInfoSubscription,
  CommentInfoSubscriptionVariables
>;
export const LabelInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LabelInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LabelBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LabelInfoSubscription,
  LabelInfoSubscriptionVariables
>;
export const TemplateInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'TemplateInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TemplateFull' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dueDateOffset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplateInfoSubscription,
  TemplateInfoSubscriptionVariables
>;
export const UserGroupInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'UserGroupInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroupInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'UserGroupBasicWithMembers',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroupBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserGroupInfoSubscription,
  UserGroupInfoSubscriptionVariables
>;
export const UserNotificationsRemoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserNotificationsRemove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'events' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'NotificationEvent' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotificationsRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'events' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'events' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserNotificationsRemoveMutation,
  UserNotificationsRemoveMutationVariables
>;
export const TopicInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'TopicInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'TopicWithMembers' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables
>;
export const UserInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'UserInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMessages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserInfoSubscription,
  UserInfoSubscriptionVariables
>;
export const MessageInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'MessageInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topicId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageFeed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserGroupBasic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedMembers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserBasic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checklist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ChecklistItemBasic',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'readBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserBasic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'readPercentage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageInfoSubscription,
  MessageInfoSubscriptionVariables
>;
export const FileSignedDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FileSignedDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isDownloadUrl' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileSignedDownloadUrlForMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDownloadUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isDownloadUrl' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FileSignedDownloadUrlQuery,
  FileSignedDownloadUrlQueryVariables
>;
export const FileSignedUploadPostFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FileSignedUploadPostFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileNames' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileSignedUploadPostFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileNames' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileNames' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FileSignedUploadPostFieldsQuery,
  FileSignedUploadPostFieldsQueryVariables
>;
export const ReservationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Reservations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReservationBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GuestBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Guest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surname' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReservationBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reservation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedGuids' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'guid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'guests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GuestBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stayEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stayStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReservationsQuery, ReservationsQueryVariables>;
export const LabelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Labels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelsQuery, LabelsQueryVariables>;
export const AssigneesFullDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AssigneesFull' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicAssignableGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicAssignableMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssigneesFullQuery, AssigneesFullQueryVariables>;
export const ReservationStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReservationStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'connected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReservationStatusQuery,
  ReservationStatusQueryVariables
>;
export const AssigneesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Assignees' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssigneesQuery, AssigneesQueryVariables>;
export const ChecklistItemCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checklistItemCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isChecked' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'position' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistCreateItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checked' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isChecked' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'position' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'position' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChecklistItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChecklistItemCreateMutation,
  ChecklistItemCreateMutationVariables
>;
export const DashboardKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardKpi' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardKpi' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchQuery' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SearchQueryBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardKpiType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchQueryBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchQuery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'title' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'de' } },
                { kind: 'Field', name: { kind: 'Name', value: 'en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'it' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardKpiQuery, DashboardKpiQueryVariables>;
export const ChecklistItemSetStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checklistItemSetState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistCheckItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'checked' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'state' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChecklistItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChecklistItemSetStateMutation,
  ChecklistItemSetStateMutationVariables
>;
export const ChecklistItemDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checklistItemDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistDeleteItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChecklistItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChecklistItemDeleteMutation,
  ChecklistItemDeleteMutationVariables
>;
export const ChecklistItemReorderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checklistItemReorder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'position' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistReorder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'checklistId' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'itemId' },
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'newPosition' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'position' },
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChecklistItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChecklistItemReorderMutation,
  ChecklistItemReorderMutationVariables
>;
export const FilesDeleteFromMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FilesDeleteFromMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesDeleteFromMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AttachmentBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FilesDeleteFromMessageMutation,
  FilesDeleteFromMessageMutationVariables
>;
export const UserGroupDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserGroupDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroupDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserGroupDeleteMutation,
  UserGroupDeleteMutationVariables
>;
export const ChecklistItemSetTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checklistItemSetText' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklistUpdateItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChecklistItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChecklistItemSetTextMutation,
  ChecklistItemSetTextMutationVariables
>;
export const LabelDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LabelDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelDeleteMutation, LabelDeleteMutationVariables>;
export const GroupCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GroupCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGroupCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroupCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupCreateMutation, GroupCreateMutationVariables>;
export const GroupUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GroupUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserGroupUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroupUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupUpdateMutation, GroupUpdateMutationVariables>;
export const FilesAddToMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FilesAddToMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesAddToMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keys' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keys' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AttachmentBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FilesAddToMessageMutation,
  FilesAddToMessageMutationVariables
>;
export const LabelCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LabelCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Color' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelCreateMutation, LabelCreateMutationVariables>;
export const MessageArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageArchive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageArchiveMutation,
  MessageArchiveMutationVariables
>;
export const MessageUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MessageUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageCommon' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageUpdateMutation,
  MessageUpdateMutationVariables
>;
export const MessageUnArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageUnArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageUnarchive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'readPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageUnArchiveMutation,
  MessageUnArchiveMutationVariables
>;
export const MessageDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageDeleteMutation,
  MessageDeleteMutationVariables
>;
export const MessageCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'readPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageCreateMutation,
  MessageCreateMutationVariables
>;
export const MessageStatusUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessageStatusUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MessageStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageStatusUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageStatusUpdateMutation,
  MessageStatusUpdateMutationVariables
>;
export const PushSubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pushSubscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pushNotificationSubscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'device' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PushSubscribeMutation,
  PushSubscribeMutationVariables
>;
export const MessagesReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MessagesRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messagesRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessagesReadMutation,
  MessagesReadMutationVariables
>;
export const LabelUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LabelUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Color' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LabelUpdateMutation, LabelUpdateMutationVariables>;
export const RoqTokenCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RoqTokenCreate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roqTokenCreate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RoqTokenCreateMutation,
  RoqTokenCreateMutationVariables
>;
export const RecurringRuleSaveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecurringRuleSave' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RecurringRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRuleSave' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecurringRuleSaveMutation,
  RecurringRuleSaveMutationVariables
>;
export const PushUnsubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pushUnsubscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pushNotificationUnsubscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PushUnsubscribeMutation,
  PushUnsubscribeMutationVariables
>;
export const TopicCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TopicCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isPrivate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'members' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groups' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isPrivate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isPrivate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'members' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'members' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groups' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicWithMembers' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicCreateMutation, TopicCreateMutationVariables>;
export const RecurringRuleDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecurringRuleDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRuleDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecurringRuleDeleteMutation,
  RecurringRuleDeleteMutationVariables
>;
export const TemplateUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TemplateUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TemplateUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TemplateFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dueDateOffset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplateUpdateMutation,
  TemplateUpdateMutationVariables
>;
export const TemplateCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TemplateCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TemplateFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dueDateOffset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplateCreateMutation,
  TemplateCreateMutationVariables
>;
export const TopicUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TopicUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TopicUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicWithMembers' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicUpdateMutation, TopicUpdateMutationVariables>;
export const TopicDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TopicDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicDeleteMutation, TopicDeleteMutationVariables>;
export const UserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupsQuery, UserGroupsQueryVariables>;
export const UserSelectHotelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserSelectHotel' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userSelectHotel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedHotel' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotelPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSelectHotelMutation,
  UserSelectHotelMutationVariables
>;
export const UserUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotelPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetLanguage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUpdateMutation, UserUpdateMutationVariables>;
export const NotificationsLocalizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationsLocalization' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationsLocalizationQuery,
  NotificationsLocalizationQueryVariables
>;
export const UserDeactivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserDeactivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDeactivate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotelPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
export const TemplateDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TemplateDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplateDeleteMutation,
  TemplateDeleteMutationVariables
>;
export const UserActivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserActivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userActivate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotelPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserActivateMutation,
  UserActivateMutationVariables
>;
export const UserLocalizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserLocalization' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targetLanguages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserLocalizationQuery,
  UserLocalizationQueryVariables
>;
export const UserGroupsWithMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserGroupsWithMembers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasicWithMembers' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserGroupBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserGroupsWithMembersQuery,
  UserGroupsWithMembersQueryVariables
>;
export const PushInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PushInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserMe' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pushSubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'device' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HotelPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isChatEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTesting' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMe' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMessages' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HotelPermissions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedHotel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PushInfoQuery, PushInfoQueryVariables>;
export const MessageLabelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageLabels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LabelBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageLabelsQuery, MessageLabelsQueryVariables>;
export const MessageFullDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageFull' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'readPercentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFullQuery, MessageFullQueryVariables>;
export const MessageTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageText' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageTextQuery, MessageTextQueryVariables>;
export const MessageDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reminders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReminderItemBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageDateQuery, MessageDateQueryVariables>;
export const PushSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pushSubscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pushSubscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'device' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PushSubscriptionsQuery,
  PushSubscriptionsQueryVariables
>;
export const CommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Comments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeTranslations' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Comment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CommentFull' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CommentFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'translation' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeTranslations' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isForExternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentsQuery, CommentsQueryVariables>;
export const MessageRightSidebarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageRightSidebar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessageCommon' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageRightSidebarQuery,
  MessageRightSidebarQueryVariables
>;
export const MessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topicId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Message' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MessageFeed' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesQuery, MessagesQueryVariables>;
export const MessageRankedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageRankedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SearchParams' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageRankedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Message' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MessageFeed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageRankedSearchQuery,
  MessageRankedSearchQueryVariables
>;
export const MessageIsArchivedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageIsArchived' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageIsArchivedQuery,
  MessageIsArchivedQueryVariables
>;
export const MessageTranslationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageTranslation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'translation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checklist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MessageTranslationQuery,
  MessageTranslationQueryVariables
>;
export const SearchQueriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchQueries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchQueryType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchQueries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchQueryBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchQueryBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchQuery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'title' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'de' } },
                { kind: 'Field', name: { kind: 'Name', value: 'en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'it' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQueriesQuery, SearchQueriesQueryVariables>;
export const MessageSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MessageSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Message' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MessageFeed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageSearchQuery, MessageSearchQueryVariables>;
export const TasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TasksFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagination' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageFeed' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodesInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returned' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creatorType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AttachmentBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSeen' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfComments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isArchivedByGuest' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSupportingReplies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionLanguage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageFeed' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MessageCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TasksQuery, TasksQueryVariables>;
export const TemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Template' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templateId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TemplateFull' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChecklistItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ChecklistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabelBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Label' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReminderItemBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFull' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignedMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ChecklistItemBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dueDateOffset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabelBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReminderItemBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateQuery, TemplateQueryVariables>;
export const TemplatesInTopicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplatesInTopic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templatesInTopic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topicId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TemplateBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplatesInTopicQuery,
  TemplatesInTopicQueryVariables
>;
export const TopicBasicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicBasic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicBasicQuery, TopicBasicQueryVariables>;
export const SearchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchQueryContext' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchQueryBasic' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sort' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchQueryBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchQuery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'title' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'de' } },
                { kind: 'Field', name: { kind: 'Name', value: 'en' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'it' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQueryQuery, SearchQueryQueryVariables>;
export const TopicSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicsAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicWithMembers' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicSettingsQuery, TopicSettingsQueryVariables>;
export const TopicAssignableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicAssignable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'topicId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicAssignableGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topicAssignableMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'topicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopicAssignableQuery,
  TopicAssignableQueryVariables
>;
export const TopicDescriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopicDescriptionQuery,
  TopicDescriptionQueryVariables
>;
export const TopicTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicTitleQuery, TopicTitleQueryVariables>;
export const TopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Topics' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicsQuery, TopicsQueryVariables>;
export const TopicsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unreadMessages' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicsListQuery, TopicsListQueryVariables>;
export const TopicWithMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicWithMembers' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicWithMembers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TopicBasic' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadMessages' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopicWithMembersQuery,
  TopicWithMembersQueryVariables
>;
export const LocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Locale' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'translations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localization' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocaleQuery, LocaleQueryVariables>;
export const TopicsUnreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicsUnread' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unreadMessages' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicsUnreadQuery, TopicsUnreadQueryVariables>;
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserMe' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HotelPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isChatEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTesting' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserMe' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasMessages' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'HotelPermissions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedHotel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const UserNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GroupedNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GroupedNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'TOPICS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'USER_GROUPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'LABELS' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_CREATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_ASSIGNEES' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_UPDATED__FOR_CREATOR' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_ARCHIVED' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'MESSAGE_IS_DELETED' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'REMINDERS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'COMMENTS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'CHAT' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserNotifications' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selected' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupedNotifications' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserNotificationsQuery,
  UserNotificationsQueryVariables
>;
export const TopicAssigneesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicAssignees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserBasic' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicAssigneesQuery, TopicAssigneesQueryVariables>;
export const UserSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HotelPermissions' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'UserBasic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetLanguage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HotelPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelPermissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hotel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isChatEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isTesting' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSettingsQuery, UserSettingsQueryVariables>;
export const TemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Templates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templatesEditable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TemplateBasic' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TopicBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Topic' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateBasic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Template' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurringRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rule' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TopicBasic' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupBasic' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserBasic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplatesQuery, TemplatesQueryVariables>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Node: Message | Comment;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Color: ResolverTypeWrapper<Scalars['Color']['output']>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  ActionType: ActionType;
  Locale: Locale;
  TargetLanguage: TargetLanguage;
  ActionResult: ResolverTypeWrapper<ActionResult>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  TimeUnit: TimeUnit;
  Node: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Node']>;
  PaginationInput: PaginationInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  NodesInfo: ResolverTypeWrapper<NodesInfo>;
  PaginationResult: ResolverTypeWrapper<
    Omit<PaginationResult, 'nodes'> & { nodes: Array<ResolversTypes['Node']> }
  >;
  PaginationResultExtended: ResolverTypeWrapper<
    Omit<PaginationResultExtended, 'nodes'> & {
      nodes: Array<ResolversTypes['Node']>;
    }
  >;
  I18nLocalization: ResolverTypeWrapper<I18nLocalization>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  I18nTargetLanguage: ResolverTypeWrapper<I18nTargetLanguage>;
  I18nRole: ResolverTypeWrapper<I18nRole>;
  I18n: ResolverTypeWrapper<I18n>;
  Query: ResolverTypeWrapper<{}>;
  UserActionType: UserActionType;
  UserStatus: UserStatus;
  User: ResolverTypeWrapper<User>;
  HotelPermissions: ResolverTypeWrapper<HotelPermissions>;
  LoginRes: ResolverTypeWrapper<LoginRes>;
  LogoutRes: ResolverTypeWrapper<LogoutRes>;
  UserInfo: ResolverTypeWrapper<UserInfo>;
  UserCredentials: UserCredentials;
  UserUpdateInput: UserUpdateInput;
  Mutation: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  NotificationEvent: NotificationEvent;
  NotificationType: NotificationType;
  NotificationGroup: NotificationGroup;
  GroupedNotifications: ResolverTypeWrapper<GroupedNotifications>;
  UserNotifications: ResolverTypeWrapper<UserNotifications>;
  UserGroup: ResolverTypeWrapper<UserGroup>;
  UserGroupInfo: ResolverTypeWrapper<UserGroupInfo>;
  UserGroupCreateInput: UserGroupCreateInput;
  UserGroupUpdateInput: UserGroupUpdateInput;
  Hotel: ResolverTypeWrapper<Hotel>;
  NumberOfHotelUsersForAnalytics: ResolverTypeWrapper<NumberOfHotelUsersForAnalytics>;
  HotelForAnalytics: ResolverTypeWrapper<HotelForAnalytics>;
  HotelStatus: HotelStatus;
  HotelUpdateInput: HotelUpdateInput;
  LabelInput: LabelInput;
  Label: ResolverTypeWrapper<Label>;
  LabelInfo: ResolverTypeWrapper<LabelInfo>;
  TopicType: TopicType;
  Topic: ResolverTypeWrapper<Topic>;
  TopicInfo: ResolverTypeWrapper<TopicInfo>;
  TopicCreateInput: TopicCreateInput;
  TopicUpdateInput: TopicUpdateInput;
  ReminderInput: ReminderInput;
  Reminder: ResolverTypeWrapper<Reminder>;
  ReminderOption: ResolverTypeWrapper<ReminderOption>;
  MessageType: MessageType;
  MessageStatus: MessageStatus;
  MessageCreatorType: MessageCreatorType;
  MessageInput: MessageInput;
  MessageUpdateInput: MessageUpdateInput;
  Thread: ResolverTypeWrapper<Thread>;
  MessageTranslation: ResolverTypeWrapper<MessageTranslation>;
  Message: ResolverTypeWrapper<Message>;
  MessageInfo: ResolverTypeWrapper<MessageInfo>;
  DeviceInput: DeviceInput;
  PushNotification: ResolverTypeWrapper<PushNotification>;
  Device: ResolverTypeWrapper<Device>;
  CommentInput: CommentInput;
  CommentUpdateInput: CommentUpdateInput;
  CommentTranslation: ResolverTypeWrapper<CommentTranslation>;
  Comment: ResolverTypeWrapper<Comment>;
  CommentInfo: ResolverTypeWrapper<CommentInfo>;
  ChecklistUpdateItemInput: ChecklistUpdateItemInput;
  ChecklistCheckItemInput: ChecklistCheckItemInput;
  ChecklistItemInput: ChecklistItemInput;
  ChecklistItemMessageCreateInput: ChecklistItemMessageCreateInput;
  ChecklistReorderInput: ChecklistReorderInput;
  ChecklistItemTranslation: ResolverTypeWrapper<ChecklistItemTranslation>;
  ChecklistItem: ResolverTypeWrapper<ChecklistItem>;
  ThreadMetaInput: ThreadMetaInput;
  ExternalMessageStoreInput: ExternalMessageStoreInput;
  ExternalThreadUpdateInput: ExternalThreadUpdateInput;
  File: ResolverTypeWrapper<File>;
  SearchInfo: ResolverTypeWrapper<SearchInfo>;
  SearchResult: ResolverTypeWrapper<SearchResult>;
  SearchParams: SearchParams;
  SearchQueryType: SearchQueryType;
  DashboardKpiType: DashboardKpiType;
  SearchQueryContext: SearchQueryContext;
  SearchQueryTitle: ResolverTypeWrapper<SearchQueryTitle>;
  SearchQuery: ResolverTypeWrapper<SearchQuery>;
  DashboardKpi: ResolverTypeWrapper<DashboardKpi>;
  ChecklistItemTemplateCreateInput: ChecklistItemTemplateCreateInput;
  ChecklistItemTemplateUpdateInput: ChecklistItemTemplateUpdateInput;
  TemplateInput: TemplateInput;
  TemplateUpdateInput: TemplateUpdateInput;
  DateOffsetInput: DateOffsetInput;
  DateOffset: ResolverTypeWrapper<DateOffset>;
  Template: ResolverTypeWrapper<Template>;
  TemplateInfo: ResolverTypeWrapper<TemplateInfo>;
  RecurringRule: ResolverTypeWrapper<RecurringRule>;
  RecurringRuleInput: RecurringRuleInput;
  ReservationStatus: ResolverTypeWrapper<ReservationStatus>;
  Guest: ResolverTypeWrapper<Guest>;
  ConnectedGuid: ResolverTypeWrapper<ConnectedGuid>;
  Reservation: ResolverTypeWrapper<Reservation>;
  RoqToken: ResolverTypeWrapper<RoqToken>;
  TasksFilterInput: TasksFilterInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Color: Scalars['Color']['output'];
  Date: Scalars['Date']['output'];
  ActionResult: ActionResult;
  Boolean: Scalars['Boolean']['output'];
  Node: ResolversUnionTypes<ResolversParentTypes>['Node'];
  PaginationInput: PaginationInput;
  Int: Scalars['Int']['output'];
  ID: Scalars['ID']['output'];
  PageInfo: PageInfo;
  NodesInfo: NodesInfo;
  PaginationResult: Omit<PaginationResult, 'nodes'> & {
    nodes: Array<ResolversParentTypes['Node']>;
  };
  PaginationResultExtended: Omit<PaginationResultExtended, 'nodes'> & {
    nodes: Array<ResolversParentTypes['Node']>;
  };
  I18nLocalization: I18nLocalization;
  String: Scalars['String']['output'];
  I18nTargetLanguage: I18nTargetLanguage;
  I18nRole: I18nRole;
  I18n: I18n;
  Query: {};
  User: User;
  HotelPermissions: HotelPermissions;
  LoginRes: LoginRes;
  LogoutRes: LogoutRes;
  UserInfo: UserInfo;
  UserCredentials: UserCredentials;
  UserUpdateInput: UserUpdateInput;
  Mutation: {};
  Subscription: {};
  GroupedNotifications: GroupedNotifications;
  UserNotifications: UserNotifications;
  UserGroup: UserGroup;
  UserGroupInfo: UserGroupInfo;
  UserGroupCreateInput: UserGroupCreateInput;
  UserGroupUpdateInput: UserGroupUpdateInput;
  Hotel: Hotel;
  NumberOfHotelUsersForAnalytics: NumberOfHotelUsersForAnalytics;
  HotelForAnalytics: HotelForAnalytics;
  HotelUpdateInput: HotelUpdateInput;
  LabelInput: LabelInput;
  Label: Label;
  LabelInfo: LabelInfo;
  Topic: Topic;
  TopicInfo: TopicInfo;
  TopicCreateInput: TopicCreateInput;
  TopicUpdateInput: TopicUpdateInput;
  ReminderInput: ReminderInput;
  Reminder: Reminder;
  ReminderOption: ReminderOption;
  MessageInput: MessageInput;
  MessageUpdateInput: MessageUpdateInput;
  Thread: Thread;
  MessageTranslation: MessageTranslation;
  Message: Message;
  MessageInfo: MessageInfo;
  DeviceInput: DeviceInput;
  PushNotification: PushNotification;
  Device: Device;
  CommentInput: CommentInput;
  CommentUpdateInput: CommentUpdateInput;
  CommentTranslation: CommentTranslation;
  Comment: Comment;
  CommentInfo: CommentInfo;
  ChecklistUpdateItemInput: ChecklistUpdateItemInput;
  ChecklistCheckItemInput: ChecklistCheckItemInput;
  ChecklistItemInput: ChecklistItemInput;
  ChecklistItemMessageCreateInput: ChecklistItemMessageCreateInput;
  ChecklistReorderInput: ChecklistReorderInput;
  ChecklistItemTranslation: ChecklistItemTranslation;
  ChecklistItem: ChecklistItem;
  ThreadMetaInput: ThreadMetaInput;
  ExternalMessageStoreInput: ExternalMessageStoreInput;
  ExternalThreadUpdateInput: ExternalThreadUpdateInput;
  File: File;
  SearchInfo: SearchInfo;
  SearchResult: SearchResult;
  SearchParams: SearchParams;
  SearchQueryContext: SearchQueryContext;
  SearchQueryTitle: SearchQueryTitle;
  SearchQuery: SearchQuery;
  DashboardKpi: DashboardKpi;
  ChecklistItemTemplateCreateInput: ChecklistItemTemplateCreateInput;
  ChecklistItemTemplateUpdateInput: ChecklistItemTemplateUpdateInput;
  TemplateInput: TemplateInput;
  TemplateUpdateInput: TemplateUpdateInput;
  DateOffsetInput: DateOffsetInput;
  DateOffset: DateOffset;
  Template: Template;
  TemplateInfo: TemplateInfo;
  RecurringRule: RecurringRule;
  RecurringRuleInput: RecurringRuleInput;
  ReservationStatus: ReservationStatus;
  Guest: Guest;
  ConnectedGuid: ConnectedGuid;
  Reservation: Reservation;
  RoqToken: RoqToken;
  TasksFilterInput: TasksFilterInput;
};

export type OneOfDirectiveArgs = {};

export type OneOfDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = OneOfDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface ColorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Color'], any> {
  name: 'Color';
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type ActionResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActionResult'] = ResolversParentTypes['ActionResult'],
> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NodeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Node'] = ResolversParentTypes['Node'],
> = {
  __resolveType: TypeResolveFn<'Message' | 'Comment', ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  startCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  endCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NodesInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NodesInfo'] = ResolversParentTypes['NodesInfo'],
> = {
  returned?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaginationResult'] = ResolversParentTypes['PaginationResult'],
> = {
  nodes?: Resolver<Array<ResolversTypes['Node']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationResultExtendedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaginationResultExtended'] = ResolversParentTypes['PaginationResultExtended'],
> = {
  nodes?: Resolver<Array<ResolversTypes['Node']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  nodesInfo?: Resolver<ResolversTypes['NodesInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type I18nLocalizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['I18nLocalization'] = ResolversParentTypes['I18nLocalization'],
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type I18nTargetLanguageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['I18nTargetLanguage'] = ResolversParentTypes['I18nTargetLanguage'],
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type I18nRoleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['I18nRole'] = ResolversParentTypes['I18nRole'],
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type I18nResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['I18n'] = ResolversParentTypes['I18n'],
> = {
  targetLanguages?: Resolver<
    Array<ResolversTypes['I18nTargetLanguage']>,
    ParentType,
    ContextType
  >;
  roles?: Resolver<Array<ResolversTypes['I18nRole']>, ParentType, ContextType>;
  notifications?: Resolver<
    Array<ResolversTypes['I18nLocalization']>,
    ParentType,
    ContextType
  >;
  translations?: Resolver<
    Array<ResolversTypes['I18nLocalization']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  localization?: Resolver<ResolversTypes['I18n'], ParentType, ContextType>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  usersAll?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  user?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, 'id'>
  >;
  userNotifications?: Resolver<
    ResolversTypes['UserNotifications'],
    ParentType,
    ContextType,
    RequireFields<QueryUserNotificationsArgs, 'type'>
  >;
  userGroups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType
  >;
  userGroup?: Resolver<
    ResolversTypes['UserGroup'],
    ParentType,
    ContextType,
    RequireFields<QueryUserGroupArgs, 'id'>
  >;
  hotel?: Resolver<
    Maybe<ResolversTypes['Hotel']>,
    ParentType,
    ContextType,
    RequireFields<QueryHotelArgs, 'id'>
  >;
  hotels?: Resolver<Array<ResolversTypes['Hotel']>, ParentType, ContextType>;
  hotelsForAnalytics?: Resolver<
    Array<ResolversTypes['HotelForAnalytics']>,
    ParentType,
    ContextType
  >;
  label?: Resolver<
    Maybe<ResolversTypes['Label']>,
    ParentType,
    ContextType,
    RequireFields<QueryLabelArgs, 'id'>
  >;
  labels?: Resolver<Array<ResolversTypes['Label']>, ParentType, ContextType>;
  topics?: Resolver<Array<ResolversTypes['Topic']>, ParentType, ContextType>;
  topicsAll?: Resolver<Array<ResolversTypes['Topic']>, ParentType, ContextType>;
  topic?: Resolver<
    ResolversTypes['Topic'],
    ParentType,
    ContextType,
    RequireFields<QueryTopicArgs, 'id'>
  >;
  topicAssignableMembers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryTopicAssignableMembersArgs, 'id'>
  >;
  topicAssignableGroups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryTopicAssignableGroupsArgs, 'id'>
  >;
  message?: Resolver<
    Maybe<ResolversTypes['Message']>,
    ParentType,
    ContextType,
    RequireFields<QueryMessageArgs, 'id'>
  >;
  messages?: Resolver<
    ResolversTypes['PaginationResult'],
    ParentType,
    ContextType,
    RequireFields<QueryMessagesArgs, 'topicId'>
  >;
  pushSubscriptions?: Resolver<
    Array<ResolversTypes['PushNotification']>,
    ParentType,
    ContextType
  >;
  comment?: Resolver<
    Maybe<ResolversTypes['Comment']>,
    ParentType,
    ContextType,
    RequireFields<QueryCommentArgs, 'id'>
  >;
  comments?: Resolver<
    ResolversTypes['PaginationResult'],
    ParentType,
    ContextType,
    RequireFields<QueryCommentsArgs, 'messageId'>
  >;
  fileSignedUploadPostFields?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryFileSignedUploadPostFieldsArgs, 'fileNames'>
  >;
  fileSignedDownloadUrlForMessage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryFileSignedDownloadUrlForMessageArgs, 'key' | 'messageId'>
  >;
  fileSignedDownloadUrlForComment?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryFileSignedDownloadUrlForCommentArgs, 'key' | 'commentId'>
  >;
  messageSearch?: Resolver<
    ResolversTypes['SearchResult'],
    ParentType,
    ContextType,
    Partial<QueryMessageSearchArgs>
  >;
  messageRankedSearch?: Resolver<
    ResolversTypes['SearchResult'],
    ParentType,
    ContextType,
    RequireFields<QueryMessageRankedSearchArgs, 'params'>
  >;
  searchQuery?: Resolver<
    ResolversTypes['SearchQuery'],
    ParentType,
    ContextType,
    RequireFields<QuerySearchQueryArgs, 'id'>
  >;
  searchQueries?: Resolver<
    Array<ResolversTypes['SearchQuery']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchQueriesArgs, 'type'>
  >;
  dashboardKpi?: Resolver<
    Array<ResolversTypes['DashboardKpi']>,
    ParentType,
    ContextType
  >;
  messageSync?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType
  >;
  templatesEditable?: Resolver<
    Array<ResolversTypes['Template']>,
    ParentType,
    ContextType
  >;
  templatesInTopic?: Resolver<
    Array<ResolversTypes['Template']>,
    ParentType,
    ContextType,
    RequireFields<QueryTemplatesInTopicArgs, 'topicId'>
  >;
  template?: Resolver<
    ResolversTypes['Template'],
    ParentType,
    ContextType,
    RequireFields<QueryTemplateArgs, 'id'>
  >;
  reservationStatus?: Resolver<
    ResolversTypes['ReservationStatus'],
    ParentType,
    ContextType
  >;
  reservations?: Resolver<
    Array<ResolversTypes['Reservation']>,
    ParentType,
    ContextType
  >;
  tasks?: Resolver<
    ResolversTypes['PaginationResultExtended'],
    ParentType,
    ContextType,
    RequireFields<QueryTasksArgs, 'filter' | 'pagination'>
  >;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  targetLanguage?: Resolver<
    Maybe<ResolversTypes['TargetLanguage']>,
    ParentType,
    ContextType
  >;
  localization?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hotelPermissions?: Resolver<
    Array<ResolversTypes['HotelPermissions']>,
    ParentType,
    ContextType
  >;
  userGroups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  selectedHotel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hasMessages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelPermissionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HotelPermissions'] = ResolversParentTypes['HotelPermissions'],
> = {
  hotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  permissions?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LoginRes'] = ResolversParentTypes['LoginRes'],
> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isFirstLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoutResResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LogoutRes'] = ResolversParentTypes['LogoutRes'],
> = {
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo'],
> = {
  action?: Resolver<ResolversTypes['UserActionType'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  login?: Resolver<
    ResolversTypes['LoginRes'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginArgs, 'code'>
  >;
  loginWithCredentials?: Resolver<
    ResolversTypes['LoginRes'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginWithCredentialsArgs, 'credentials'>
  >;
  logout?: Resolver<ResolversTypes['LogoutRes'], ParentType, ContextType>;
  userActivate?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUserActivateArgs, 'id'>
  >;
  userDeactivate?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUserDeactivateArgs, 'id'>
  >;
  userUpdate?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUserUpdateArgs, 'id' | 'data'>
  >;
  userSelectHotel?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userNotificationsAdd?: Resolver<
    ResolversTypes['UserNotifications'],
    ParentType,
    ContextType,
    RequireFields<MutationUserNotificationsAddArgs, 'type' | 'events'>
  >;
  userNotificationsRemove?: Resolver<
    ResolversTypes['UserNotifications'],
    ParentType,
    ContextType,
    RequireFields<MutationUserNotificationsRemoveArgs, 'type' | 'events'>
  >;
  userNotificationsUpdate?: Resolver<
    ResolversTypes['UserNotifications'],
    ParentType,
    ContextType,
    RequireFields<MutationUserNotificationsUpdateArgs, 'type' | 'events'>
  >;
  userGroupCreate?: Resolver<
    ResolversTypes['UserGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationUserGroupCreateArgs, 'data'>
  >;
  userGroupUpdate?: Resolver<
    ResolversTypes['UserGroup'],
    ParentType,
    ContextType,
    RequireFields<MutationUserGroupUpdateArgs, 'id' | 'data'>
  >;
  userGroupDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationUserGroupDeleteArgs, 'id'>
  >;
  hotelUpdate?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationHotelUpdateArgs, 'uuid' | 'data'>
  >;
  hotelSetChatFeature?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationHotelSetChatFeatureArgs, 'uuid' | 'isChatEnabled'>
  >;
  labelCreate?: Resolver<
    ResolversTypes['Label'],
    ParentType,
    ContextType,
    RequireFields<MutationLabelCreateArgs, 'data'>
  >;
  labelUpdate?: Resolver<
    ResolversTypes['Label'],
    ParentType,
    ContextType,
    RequireFields<MutationLabelUpdateArgs, 'id' | 'data'>
  >;
  labelDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationLabelDeleteArgs, 'id'>
  >;
  topicCreate?: Resolver<
    ResolversTypes['Topic'],
    ParentType,
    ContextType,
    RequireFields<MutationTopicCreateArgs, 'data'>
  >;
  topicUpdate?: Resolver<
    ResolversTypes['Topic'],
    ParentType,
    ContextType,
    RequireFields<MutationTopicUpdateArgs, 'id' | 'data'>
  >;
  topicDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationTopicDeleteArgs, 'id'>
  >;
  messageCreate?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageCreateArgs, 'data'>
  >;
  messageUpdate?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageUpdateArgs, 'id' | 'data'>
  >;
  messageDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageDeleteArgs, 'id'>
  >;
  messageStatusUpdate?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageStatusUpdateArgs, 'id' | 'status'>
  >;
  messageArchive?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageArchiveArgs, 'id'>
  >;
  messageUnarchive?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationMessageUnarchiveArgs, 'id'>
  >;
  pushNotificationSubscribe?: Resolver<
    ResolversTypes['PushNotification'],
    ParentType,
    ContextType,
    RequireFields<MutationPushNotificationSubscribeArgs, 'token' | 'device'>
  >;
  pushNotificationUnsubscribe?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationPushNotificationUnsubscribeArgs, 'token'>
  >;
  commentCreate?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationCommentCreateArgs, 'data'>
  >;
  commentUpdate?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationCommentUpdateArgs, 'id' | 'data'>
  >;
  commentDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCommentDeleteArgs, 'id'>
  >;
  checklistCreateItem?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationChecklistCreateItemArgs, 'messageId' | 'data'>
  >;
  checklistUpdateItem?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationChecklistUpdateItemArgs, 'id' | 'messageId' | 'data'>
  >;
  checklistCheckItem?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationChecklistCheckItemArgs, 'id' | 'messageId' | 'data'>
  >;
  checklistDeleteItem?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationChecklistDeleteItemArgs, 'id' | 'messageId'>
  >;
  checklistReorder?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationChecklistReorderArgs, 'messageId' | 'data'>
  >;
  externalMessageStore?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalMessageStoreArgs, 'data'>
  >;
  externalThreadUpdate?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalThreadUpdateArgs, 'threadId' | 'data'>
  >;
  filesAddToMessage?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationFilesAddToMessageArgs, 'keys' | 'messageId'>
  >;
  filesDeleteFromMessage?: Resolver<
    ResolversTypes['Message'],
    ParentType,
    ContextType,
    RequireFields<MutationFilesDeleteFromMessageArgs, 'ids' | 'messageId'>
  >;
  filesAddToComment?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationFilesAddToCommentArgs, 'keys' | 'commentId'>
  >;
  filesDeleteFromComment?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationFilesDeleteFromCommentArgs, 'ids' | 'commentId'>
  >;
  messagesRead?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationMessagesReadArgs, 'messageIds'>
  >;
  templateCreate?: Resolver<
    ResolversTypes['Template'],
    ParentType,
    ContextType,
    RequireFields<MutationTemplateCreateArgs, 'data'>
  >;
  templateUpdate?: Resolver<
    ResolversTypes['Template'],
    ParentType,
    ContextType,
    RequireFields<MutationTemplateUpdateArgs, 'id' | 'data'>
  >;
  templateDelete?: Resolver<
    ResolversTypes['ActionResult'],
    ParentType,
    ContextType,
    RequireFields<MutationTemplateDeleteArgs, 'id'>
  >;
  recurringRuleSave?: Resolver<
    ResolversTypes['Template'],
    ParentType,
    ContextType,
    RequireFields<MutationRecurringRuleSaveArgs, 'templateId' | 'data'>
  >;
  recurringRuleDelete?: Resolver<
    ResolversTypes['Template'],
    ParentType,
    ContextType,
    RequireFields<MutationRecurringRuleDeleteArgs, 'templateId'>
  >;
  roqTokenCreate?: Resolver<
    ResolversTypes['RoqToken'],
    ParentType,
    ContextType
  >;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  userInfo?: SubscriptionResolver<
    ResolversTypes['UserInfo'],
    'userInfo',
    ParentType,
    ContextType
  >;
  userGroupInfo?: SubscriptionResolver<
    ResolversTypes['UserGroupInfo'],
    'userGroupInfo',
    ParentType,
    ContextType
  >;
  labelInfo?: SubscriptionResolver<
    ResolversTypes['LabelInfo'],
    'labelInfo',
    ParentType,
    ContextType
  >;
  topicInfo?: SubscriptionResolver<
    ResolversTypes['TopicInfo'],
    'topicInfo',
    ParentType,
    ContextType
  >;
  messageInfo?: SubscriptionResolver<
    ResolversTypes['MessageInfo'],
    'messageInfo',
    ParentType,
    ContextType,
    RequireFields<SubscriptionMessageInfoArgs, 'topicId'>
  >;
  commentInfo?: SubscriptionResolver<
    ResolversTypes['CommentInfo'],
    'commentInfo',
    ParentType,
    ContextType,
    RequireFields<SubscriptionCommentInfoArgs, 'messageId'>
  >;
  templateInfo?: SubscriptionResolver<
    ResolversTypes['TemplateInfo'],
    'templateInfo',
    ParentType,
    ContextType
  >;
};

export type GroupedNotificationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GroupedNotifications'] = ResolversParentTypes['GroupedNotifications'],
> = {
  TOPICS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  USERS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  USER_GROUPS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  LABELS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_CREATED?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_UPDATED?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_UPDATED__FOR_ASSIGNEES?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_UPDATED__FOR_CREATOR?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_ARCHIVED?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  MESSAGE_IS_DELETED?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  COMMENTS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  REMINDERS?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  CHAT?: Resolver<
    Array<ResolversTypes['NotificationEvent']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNotificationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserNotifications'] = ResolversParentTypes['UserNotifications'],
> = {
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  available?: Resolver<
    ResolversTypes['GroupedNotifications'],
    ParentType,
    ContextType
  >;
  default?: Resolver<
    ResolversTypes['GroupedNotifications'],
    ParentType,
    ContextType
  >;
  selected?: Resolver<
    ResolversTypes['GroupedNotifications'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserGroup'] = ResolversParentTypes['UserGroup'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  hotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserGroupInfo'] = ResolversParentTypes['UserGroupInfo'],
> = {
  userGroup?: Resolver<ResolversTypes['UserGroup'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Hotel'] = ResolversParentTypes['Hotel'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['HotelStatus'], ParentType, ContextType>;
  isTesting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isChatEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberOfHotelUsersForAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NumberOfHotelUsersForAnalytics'] = ResolversParentTypes['NumberOfHotelUsersForAnalytics'],
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactive?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HotelForAnalyticsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HotelForAnalytics'] = ResolversParentTypes['HotelForAnalytics'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  objectId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['HotelStatus'], ParentType, ContextType>;
  isTesting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isChatEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numberOfUsers?: Resolver<
    ResolversTypes['NumberOfHotelUsersForAnalytics'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Label'] = ResolversParentTypes['Label'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  hotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LabelInfo'] = ResolversParentTypes['LabelInfo'],
> = {
  label?: Resolver<ResolversTypes['Label'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Topic'] = ResolversParentTypes['Topic'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  hotel?: Resolver<ResolversTypes['Hotel'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  groups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TopicType']>, ParentType, ContextType>;
  unreadMessages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TopicInfo'] = ResolversParentTypes['TopicInfo'],
> = {
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['Topic']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReminderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Reminder'] = ResolversParentTypes['Reminder'],
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['TimeUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReminderOptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReminderOption'] = ResolversParentTypes['ReminderOption'],
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['TimeUnit'], ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThreadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Thread'] = ResolversParentTypes['Thread'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submissionLanguage?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  isSupportingReplies?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isArchivedByGuest?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTranslationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MessageTranslation'] = ResolversParentTypes['MessageTranslation'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Message'] = ResolversParentTypes['Message'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translation?: Resolver<
    ResolversTypes['MessageTranslation'],
    ParentType,
    ContextType
  >;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  topic?: Resolver<ResolversTypes['Topic'], ParentType, ContextType>;
  assignedMembers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  assignedGroups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['MessageType'], ParentType, ContextType>;
  creatorType?: Resolver<
    ResolversTypes['MessageCreatorType'],
    ParentType,
    ContextType
  >;
  thread?: Resolver<Maybe<ResolversTypes['Thread']>, ParentType, ContextType>;
  isAwaitingReply?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  labels?: Resolver<Array<ResolversTypes['Label']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes['MessageStatus']>,
    ParentType,
    ContextType
  >;
  checklist?: Resolver<
    Array<ResolversTypes['ChecklistItem']>,
    ParentType,
    ContextType
  >;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  reminders?: Resolver<
    Array<ResolversTypes['Reminder']>,
    ParentType,
    ContextType
  >;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  numberOfComments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  readBy?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  readPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MessageInfo'] = ResolversParentTypes['MessageInfo'],
> = {
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PushNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PushNotification'] = ResolversParentTypes['PushNotification'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Device'] = ResolversParentTypes['Device'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentTranslationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CommentTranslation'] = ResolversParentTypes['CommentTranslation'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Comment'] = ResolversParentTypes['Comment'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translation?: Resolver<
    ResolversTypes['CommentTranslation'],
    ParentType,
    ContextType
  >;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  isForExternal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CommentInfo'] = ResolversParentTypes['CommentInfo'],
> = {
  comment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistItemTranslationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChecklistItemTranslation'] = ResolversParentTypes['ChecklistItemTranslation'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChecklistItem'] = ResolversParentTypes['ChecklistItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translation?: Resolver<
    ResolversTypes['ChecklistItemTranslation'],
    ParentType,
    ContextType
  >;
  checked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['File'] = ResolversParentTypes['File'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchInfo'] = ResolversParentTypes['SearchInfo'],
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult'],
> = {
  nodes?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType>;
  searchInfo?: Resolver<ResolversTypes['SearchInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchQueryTitleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchQueryTitle'] = ResolversParentTypes['SearchQueryTitle'],
> = {
  de?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  en?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  it?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchQueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchQuery'] = ResolversParentTypes['SearchQuery'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['SearchQueryTitle'], ParentType, ContextType>;
  query?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SearchQueryType'], ParentType, ContextType>;
  dashboardKpiType?: Resolver<
    Maybe<ResolversTypes['DashboardKpiType']>,
    ParentType,
    ContextType
  >;
  relatedSearchQueryId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardKpiResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DashboardKpi'] = ResolversParentTypes['DashboardKpi'],
> = {
  searchQuery?: Resolver<
    ResolversTypes['SearchQuery'],
    ParentType,
    ContextType
  >;
  searchInfo?: Resolver<ResolversTypes['SearchInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateOffsetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DateOffset'] = ResolversParentTypes['DateOffset'],
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['TimeUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Template'] = ResolversParentTypes['Template'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  topic?: Resolver<ResolversTypes['Topic'], ParentType, ContextType>;
  assignedMembers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >;
  assignedGroups?: Resolver<
    Array<ResolversTypes['UserGroup']>,
    ParentType,
    ContextType
  >;
  labels?: Resolver<Array<ResolversTypes['Label']>, ParentType, ContextType>;
  checklist?: Resolver<
    Array<ResolversTypes['ChecklistItem']>,
    ParentType,
    ContextType
  >;
  dueDateOffset?: Resolver<
    Maybe<ResolversTypes['DateOffset']>,
    ParentType,
    ContextType
  >;
  reminders?: Resolver<
    Array<ResolversTypes['Reminder']>,
    ParentType,
    ContextType
  >;
  recurringRule?: Resolver<
    Maybe<ResolversTypes['RecurringRule']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TemplateInfo'] = ResolversParentTypes['TemplateInfo'],
> = {
  template?: Resolver<ResolversTypes['Template'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringRuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecurringRule'] = ResolversParentTypes['RecurringRule'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  rule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReservationStatus'] = ResolversParentTypes['ReservationStatus'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GuestResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Guest'] = ResolversParentTypes['Guest'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uniqueId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vipValue?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLine?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  postalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  cityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectedGuidResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectedGuid'] = ResolversParentTypes['ConnectedGuid'],
> = {
  guid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Reservation'] = ResolversParentTypes['Reservation'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requestorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uniqueId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stayStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stayEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hotelCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resIdType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  resIdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resIdSource?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  otaUpdatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updated_at?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  guestCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  guests?: Resolver<Array<ResolversTypes['Guest']>, ParentType, ContextType>;
  connectedGuids?: Resolver<
    Array<ResolversTypes['ConnectedGuid']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoqTokenResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RoqToken'] = ResolversParentTypes['RoqToken'],
> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Color?: GraphQLScalarType;
  Date?: GraphQLScalarType;
  ActionResult?: ActionResultResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  NodesInfo?: NodesInfoResolvers<ContextType>;
  PaginationResult?: PaginationResultResolvers<ContextType>;
  PaginationResultExtended?: PaginationResultExtendedResolvers<ContextType>;
  I18nLocalization?: I18nLocalizationResolvers<ContextType>;
  I18nTargetLanguage?: I18nTargetLanguageResolvers<ContextType>;
  I18nRole?: I18nRoleResolvers<ContextType>;
  I18n?: I18nResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  HotelPermissions?: HotelPermissionsResolvers<ContextType>;
  LoginRes?: LoginResResolvers<ContextType>;
  LogoutRes?: LogoutResResolvers<ContextType>;
  UserInfo?: UserInfoResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  GroupedNotifications?: GroupedNotificationsResolvers<ContextType>;
  UserNotifications?: UserNotificationsResolvers<ContextType>;
  UserGroup?: UserGroupResolvers<ContextType>;
  UserGroupInfo?: UserGroupInfoResolvers<ContextType>;
  Hotel?: HotelResolvers<ContextType>;
  NumberOfHotelUsersForAnalytics?: NumberOfHotelUsersForAnalyticsResolvers<ContextType>;
  HotelForAnalytics?: HotelForAnalyticsResolvers<ContextType>;
  Label?: LabelResolvers<ContextType>;
  LabelInfo?: LabelInfoResolvers<ContextType>;
  Topic?: TopicResolvers<ContextType>;
  TopicInfo?: TopicInfoResolvers<ContextType>;
  Reminder?: ReminderResolvers<ContextType>;
  ReminderOption?: ReminderOptionResolvers<ContextType>;
  Thread?: ThreadResolvers<ContextType>;
  MessageTranslation?: MessageTranslationResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessageInfo?: MessageInfoResolvers<ContextType>;
  PushNotification?: PushNotificationResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  CommentTranslation?: CommentTranslationResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  CommentInfo?: CommentInfoResolvers<ContextType>;
  ChecklistItemTranslation?: ChecklistItemTranslationResolvers<ContextType>;
  ChecklistItem?: ChecklistItemResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  SearchInfo?: SearchInfoResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  SearchQueryTitle?: SearchQueryTitleResolvers<ContextType>;
  SearchQuery?: SearchQueryResolvers<ContextType>;
  DashboardKpi?: DashboardKpiResolvers<ContextType>;
  DateOffset?: DateOffsetResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateInfo?: TemplateInfoResolvers<ContextType>;
  RecurringRule?: RecurringRuleResolvers<ContextType>;
  ReservationStatus?: ReservationStatusResolvers<ContextType>;
  Guest?: GuestResolvers<ContextType>;
  ConnectedGuid?: ConnectedGuidResolvers<ContextType>;
  Reservation?: ReservationResolvers<ContextType>;
  RoqToken?: RoqTokenResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
};
